import React, { useContext, useEffect, useState } from 'react';
import { RouterProvider, Route, useNavigate, NavLink, Routes, Router, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
// import router from './router'
import './assets/css/app.less'
import './assets/css/phone.less';
import { SubstrateContextProvider } from './substrate-lib';
import Hedaer from './component/Hedaer';
import Footer from './component/Footer';

function App() {

  return <SubstrateContextProvider>
    <div className='Toaster_box'>
      <Toaster />
    </div>
    <Hedaer />  
      <Outlet></Outlet>
    <Footer />
  </SubstrateContextProvider>
}

export default App;

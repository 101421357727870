// import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAdd, onHttp, getApi, DotData, onWeb3FromSource, copyPlaintext, goAdd, onWeb3FromSource2 } from '../util/api';
import bgImg from '../assets/img/bg1.png'
import closeimg from '../assets/img/closew.svg'
import shareImg from '../assets/img/share.png'
import '../assets/css/marketplace.less'
import InviterView from './InviterView';
import { SubstrateContext } from '../substrate-lib';
import { _extractEvents } from '../substrate-lib/SubstrateContext';
import TipModal from '../component/TipModal';
import { ForFailContent, ForSuccessfulContent } from '../component/ModalContent';
import toast from 'react-hot-toast';
import Loading from '../component/Loading';
const { encodeAddress } = require('@polkadot/util-crypto');

function Presale() {

  const navigate = useNavigate()

  let time: any;
  const [type, settype] = useState<string>('leaderboard')
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [inviteadd, setinviteadd] = useState<string>('');
  const [tranAdd, settranAdd] = useState<string>('');
  const [isinvite, setisinvite] = useState<boolean>(false);

  const [count, setCount] = useState<any>('--');

  const [isInfo, setisInfo] = useState<any>(null)

  const { state: { address , api, apiState } } = useContext(SubstrateContext);


  const [isFailOpen, setIsFailOpen] = useState<boolean>(false);
  const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
  const [errText, setErrText] = useState<string>('')
  const [text, settext] = useState<string>('')
  const [loading, setloading] = useState<boolean>(false)

  const [isLoad, setisLoad] = useState<boolean>(false)

  /* const endTime = '2023-12-26 13:59:00'
  const [times, settimes] = useState<any>({
    d: '0',
    h: '0',
    m: '0',
    s: '0',
  }); */

  const tableHead = [
    'Rank',
    'Address',
    'index',
  ]


  const getInvTop = async () => {
    setisLoad(true)
    const res = await onHttp({}, '/v2/Polkadot/InvTop');
    setisLoad(false)
    if (res && res.code == 0) {
      setlistArr(res.data)
    }
  }

  const isGetAdd = async () => {
    let urlStr = (window as any).location.href.split('?')[1];

    if (urlStr) {
      const params = new URLSearchParams(urlStr);
      let inadd: string = params.get('invite') || '';
      if (inadd) {

        setinviteadd(inadd);
        try {
          let add = encodeAddress(inadd, 0)
          if (add) {
            settranAdd(add)
          }
        } catch (error) {
          settranAdd('Invalid Address')
        }
      }
    }

  }

  useEffect(() => {

    isGetAdd()
    getInvTop();
    getData()

    return () => {
      clearTimeout(time);
    }
  }, []);

  useEffect(() => {
    if (address) {
      getAddrInfo(address);
    }
  }, [address])

  const getAddrInfo = async (adds: string) => {
    let res = await onHttp({
      "address": adds
    }, '/v2/Polkadot/AddrInfo');

    if (res && res.code == 0) {
      setisInfo(res.data.binded)
    }

  }

  const getData = async () => {
    let res: any = await getApi('/v2/Polkadot/Supporter')
    if (res && res.code == 0) {
      setCount(res.data.count)
    }
  }


 /*  const ontime = () => {
    let time = setInterval(() => {
      let now = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      let day = moment(endTime).diff(now, "days")
      let hours = moment(endTime).diff(now, "hours")
      let minutes = moment(endTime).diff(now, "minutes")
      let seconds = moment(endTime).diff(now, "seconds")

      let h = Math.floor((hours % 24))
      let m = Math.floor((minutes % 60))
      let s = Math.floor((seconds % 60))
      if (s < 0) {
        clearTimeout(time)
        settimes({
          d: 0,
          h: 0,
          m: 0,
          s: 0,
        })
      } else {
        settimes({
          d: day,
          h: h,
          m: m,
          s: s,
        })
      }
    }, 1000)
  } */

  const onInvite = async () => {

    if (!address) {
      toast.error('Node not loaded')
      return
    }

    if (apiState != 'READY') {
      toast.error('Node not loaded')
      return
    }


    if (inviteadd) {

      setloading(true)
      settext('loading…')

      let tx = api.tx.system.remarkWithEvent(DotData.Invite(tranAdd))

      onWeb3FromSource2(tx, address, (result: any) => {
        if (result.status.isBroadcast) {
          settext('broadcast…')
        }

        if (result.status.isFinalized) {
          const { success, error }: any = _extractEvents(api, result)

          if (success) {
            settext('success')
            setIsSuccessfulOpen(true)
            getAddrInfo(address)
            setisinvite(false)
            setinviteadd('')
            settranAdd('')
            toast.success('success')
          }
          if (error) {
            setErrText('failed')
            setIsFailOpen(true)
          }
          setloading(false)
        }
        if (result.status.isInBlock) {
          settext("broadcast…")
        }
        if (result.status.isCancelled) {
          settext('cancellation')
          setloading(false)
        }
      }, (err: any) => {
        setErrText(err.message)
        setIsFailOpen(true)
        setloading(false)
      })

    }
  }

  const onCopy = async () => {
    let url = 'https://dotscription.com/';
    if (address) {
      url = url + '?invite=' + address
    }

    let res = await copyPlaintext(url);
    if (res) {
      toast.success('copy success!')
    } else {
      toast.error('copy failed!')
    }
  }


  return (
    <>
      {
        isFailOpen && <TipModal
          type="fail"
          isOpen={true}
          onClose={() => setIsFailOpen(false)}
          children={<ForFailContent title={errText} title1="" />}
        />
      }
      {
        isSuccessfulOpen && <TipModal
          type="fail"
          isOpen={true}
          onClose={() => setIsSuccessfulOpen(false)}
          children={<ForSuccessfulContent title={text} title1="" />}
        />
      }
      {
        isinvite && <div className='invite_to'>
          <div>
            <div>
              <p></p>
              <img onClick={
                () => {
                  setisinvite(false)
                  setinviteadd('')
                  settranAdd('')
                }
              } src={closeimg} alt="" />
            </div>
            <span className={tranAdd == 'Invalid Address' ? 'red' : ''}>{tranAdd}</span>
            <input type="text" value={inviteadd} placeholder="Enter the inviter's address" onChange={(ev) => {
              try {
                let add = encodeAddress(ev.target.value, 0)
                if (add) {
                  settranAdd(add)
                }
              } catch (error) {
                settranAdd('Invalid Address')
              }
              setinviteadd(ev.target.value)
            }
            } />
            <button onClick={onInvite}
              className={(tranAdd == 'Invalid Address' || tranAdd == '' || loading) ? 'disbaleb_btn' : ''}
              disabled={(tranAdd == 'Invalid Address' || tranAdd == '' || loading)}
            >
              {loading ? text : 'Confirm'}
            </button>
          </div>

        </div>
      }

      <div className='app_box content app_invite'>

        <div className='app_con app_con_invite'>
          <div className='invite_imgs'>
            <img src={bgImg} alt="" />
          </div>

          <div className='invite_div'>
            <h2>Launched <span onClick={onCopy}>share <img src={shareImg} /></span></h2>
            <div className='invite_p_div'>
              <p className='invite_p1'>{count} <span>supporter</span></p>
            </div>

            <p className='invite_pt'><span>Pre-Launch for 3 days</span>2023.12.23 14:00 ~ 2023.12.26 13:59 UTC<br></br>Block Height：18760496</p>
            <p className='invite_pt'><span>Minting for 3 day</span>Start Time：2023-12-26 14:00 UTC<br></br>Block Height：18767696</p>
            <p className='invite_pt'>End Time：2023-12-29 13:59 UTC<br></br>Block Height：18810896</p>
            <p className='invite_p2'>
              The more friends you invite, the more shares you can allocate when casting inscriptions.
            </p>

            {/* <div className='invite_p3'>
              There’s still time left before the end:
            </div>

            <div>
              <ul className='invite_ul'>
                <li>{times.d}D</li>
                <li>{times.h}H</li>
                <li>{times.m}M</li>
                <li>{times.s}S</li>
              </ul>
            </div> */}

            {
              isInfo && <button className='Bound_btn'>
                Ready
              </button>
            }
            {
              !isInfo && <button className='line_btn' onClick={() => { isGetAdd(); setisinvite(true) }}>Join Now</button>
            }

            <button onClick={() => navigate('/token')}>Mint</button>
          </div>
        </div>

        <div className='invite_flex'>
          <h3 className={type == 'leaderboard' ? 'ups' : ''} onClick={() => settype('leaderboard')} >leaderboard</h3>
          <h3 className={type == 'inviter' ? 'ups' : ''} onClick={() => settype('inviter')} >inviter</h3>
        </div>

        <div className='app_con'>
          <div className='invite_type'>
            <h1>{type}</h1>
          </div>

          {
            type == 'leaderboard' && <div className='table_box market_box'>
              {
                listArr && listArr.length > 0 && <div className='table_head'>
                {
                  tableHead.map((item, index) => <div key={index} >
                    <div>{item}</div>
                  </div>)
                }
              </div>
              }
              
              <div className='table_con'>
                <ul className='ul'>
                  {isLoad && <Loading ></Loading>}
                  
                  {!isLoad && listArr && listArr.length > 0 && listArr.map((item, idx) => <li key={idx} onClick={() => navigate('/token', { state: item.address })} >
                    <div className={`${item.no == 1 && 'top1'} ${item.no == 2 && 'top2'} ${item.no == 3 && 'top3'} bold`}>{item.no}</div>
                    <div className='copyli'>
                      <div>
                        {onAdd(item.address)}
                      </div>
                    </div>
                    <div>{item.count} </div>

                  </li>)}
                  {
                    !isLoad && listArr && listArr.length == 0 && <div className='nodata'>
                      Yay! You have seen it all
                    </div>
                  }
                </ul>
              </div>
            </div>
          }

          {
            type == 'inviter' && <InviterView />
          }



        </div>

      </div>

    </>
  );
}

export default Presale;

const CloseButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.57235 15.371L15.8275 2.11588C16.7388 1.20459 18.0501 1.02467 18.7415 1.71606C19.4328 2.40746 19.2529 3.71874 18.3416 4.63003L5.08651 17.8852C4.17521 18.7965 2.86393 18.9764 2.17253 18.285C1.48114 17.5936 1.66106 16.2823 2.57235 15.371Z"
        fill="#84848D"
      />
      <path
        d="M15.6596 17.8852L2.40445 4.63003C1.49316 3.71874 1.31325 2.40746 2.00464 1.71606C2.69603 1.02467 4.00732 1.20459 4.91861 2.11588L18.1737 15.371C19.085 16.2823 19.265 17.5936 18.5736 18.285C17.8822 18.9764 16.5709 18.7965 15.6596 17.8852Z"
        fill="#84848D"
      />
    </svg>
  );
};

const SuccessfulTip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <circle cx="40" cy="40" r="40" fill="#FFC107" />
      <path
        d="M25.5898 41.6039L34.3372 52.4895C35.1727 53.5292 36.71 53.6537 37.7019 52.7619L60.7898 32.0039"
        stroke="white"
        strokeWidth="6.97059"
        strokeLinecap="round"
      />
    </svg>
  );
};

const FailTip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <circle cx="40" cy="40" r="40" fill="#E34335" />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(1 0 0 -1 35 60.5)"
        fill="white"
      />
      <path
        d="M40 19V43"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export {
    CloseButton,
    SuccessfulTip,
    FailTip
}

import React, {useContext, useEffect , useState} from 'react';
import toast from 'react-hot-toast';
import closeImg from '../assets/img/close.svg'
import { ForFailContent, ForSuccessfulContent } from '../component/ModalContent';
import TipModal from '../component/TipModal';
import { SubstrateContext } from '../substrate-lib';
import { _extractEvents } from '../substrate-lib/SubstrateContext';
import { DotData, onWeb3FromSource, onWeb3FromSource2 } from '../util/api';
import loadImg from '../assets/img/loading.gif'

const Deploy = (props:any)=>{

    const { state: { address , api , keyring } } = useContext(SubstrateContext);

    const [name,setName] = useState<string>('')
    const [totalSupply,settotalSupply] = useState<string>('')
    const [Limit,setLimit] = useState<string>('')
    const [gas,setgas] = useState<string>('')

    const [isFailOpen, setIsFailOpen] = useState<boolean>(false);
    const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
    const [text,settext] = useState<string>('')
    const [loading,setloading] = useState<boolean>(false)
    
    const onDisabled = ()=>{
        if(name && name.length > 1 && totalSupply && Limit && !loading){
            return false
        }
        return true
    }

    const onDeploy = ()=>{

        if(!address){
            // setIsFailOpen(true)
            // settext('Please connect the address')
            toast.error('Please connect the address')
            return
        }
        if(parseInt(Limit) > parseInt(totalSupply)){
            // setIsFailOpen(true)
            // settext('Limit Pre Mint cannot exceed Total Supply')
            toast.error('Limit Pre Mint cannot exceed Total Supply')
            return
        }

        if(parseInt(Limit) == 0){
            toast.error('Limit Pre Mint cannot be 0')
            return
        }
        if(parseInt(totalSupply) == 0){
            toast.error('Total Supply cannot be 0')
            return
        }

        setloading(true)
        settext('loading…')
        let comgas = parseFloat(gas)>0?gas.toString():'0'
        
        let tx = api.tx.system.remarkWithEvent(DotData.Delopy(parseInt(totalSupply),name,parseInt(Limit),comgas))

        onWeb3FromSource2(tx,address,(result: any)=>{
          if (result.status.isBroadcast) {
            settext('broadcast…')
          }

          if (result.status.isFinalized) {
            const { success, error }: any = _extractEvents(api, result)

            if (success) {
              settext('success')
              setIsSuccessfulOpen(true)
              toast.success('success')

            }
            if (error) {
                settext('failed')
                setIsFailOpen(true)
            }
            setloading(false)
          }
          if (result.status.isInBlock) {
            settext("broadcast…")
          }
          if (result.status.isCancelled) {
            settext('cancellation')
            setloading(false)
          }
      },(err:any)=>{
          settext(err.message)
          setIsFailOpen(true)
          setloading(false)
      })
    }

    function check(str:string){
        var temp=""
        for(var i=0;i<str.length;i++)
        if(str.charCodeAt(i)>0&&str.charCodeAt(i)<255)
        temp+=str.charAt(i)
        return temp
    }

    return <div className='deploy_box'>
            {
                isFailOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => {setIsFailOpen(false)}}
                children={<ForFailContent title={text} title1="" />}
            />
            }
            {
                isSuccessfulOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => {setIsSuccessfulOpen(false);props.onClose()}}
                children={<ForSuccessfulContent title={text} title1="" />}
                />
            }
            { !isSuccessfulOpen &&
            <div>
                <div className='deploy_top flex_sb'>
                    <h3>PRC-20</h3>
                    <img onClick={()=>{props.onClose()}} src={closeImg} alt="" />
                </div>
                <div className='deploy_con'>
                    <div>
                        <h4>Protocol:</h4>
                        <p>PRC-20</p>
                    </div>
                    <div>
                        <h4>Tick:</h4>
                        <input type="text" placeholder='ldentifier of the prc-20, like "dots' value={name} maxLength={8} onChange={(ev)=>{setName(check(ev.target.value.replace(/\s*/g,"")))}} />
                    </div>
                    <div>
                        <h4>Total Supply:</h4>
                        <input type="text" placeholder='210000000' value={totalSupply} onChange={(ev)=>{settotalSupply(ev.target.value.replace(/[^\d]/g,''))}} />
                    </div>
                    <div>
                        <h4>Limit Pre Mint:</h4>
                        <input type="text" placeholder='1000' value={Limit} onChange={(ev)=>{setLimit(ev.target.value.replace(/[^\d]/g,''))}} />
                    </div>
                    <div className='deploy_p'>
                        <h4>Commission:</h4>
                        <input type="text" placeholder='mint one time fee' value={gas} onChange={(ev)=>{setgas(ev.target.value.replace(/^\D*(\d*(?:\.\d{0,8})?).*$/g, '$1'))}} />  
                        <p>*You will receive the cost of each mint,Your account balance must be greater than 1 DOT, otherwise others will fail to mint.</p>
                    </div>
                </div>
                <button className={onDisabled()?'disbaleb_btn':''} disabled={onDisabled()} onClick={onDeploy}>
                    {loading ?<><img src={loadImg} alt="loading" style={{width:'16px'}} />{text}</>  :'Deploy'}
                </button>
                <div className='btn_box'>
                    {/* <button className='lines' onClick={()=>props.onClose()}>Cancal</button> */}
                </div>
            </div>
             }
        
    </div>
}

export default Deploy
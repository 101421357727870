const ForSaleHead = (props:any) => {
  return (
    <div>
      List<span className="dots">{props.name}</span>
      <span className="prc-20">prc-20</span>for sale
    </div>
  );
};

const CheckoutHead = () => {
  return <div>Checkout</div>;
};

const TransferHead = (props:any) => {
  return (
    <div>
      Transfer<span className="dots web">{props.name}</span>
      <span className="prc-20 web">prc-20</span> <i className="web">for address</i>
    </div>
  );
};

export { ForSaleHead, CheckoutHead, TransferHead };

import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import { onAdd, onHttp , onMomented } from '../../util/api';

function Hodlers(props:any) {
  const did = props.did
  const navigate = useNavigate();
  const [page, setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null)

  const tableHead = [
    'Dotscription',
    'Event',
    'Collecting',
    'Quantity',
    'Unit Price',
    'Total Value',
    'From',
    'To',
    'Time',
  ]


  const getlist = async (from: Number) => {
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "did": did
    }, '/v2/Polkadot/MarketOrders');
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setlistArr(res.data.list)
    }
  }

  useEffect(() => {
    getlist(1);
  }, []);

  // 分页
  const onPagination = (idx: number) => {
    setpage(idx)
    getlist(((idx - 1) * limit) + 1);
  }


  return (
    <>
      <div className='app_box content'>
        <div className='app_con orders_box'>
          <div className='table_box market_box' style={{ marginTop: 0 }}>
            {
              listArr.length > 0 && <div className='table_head webf'>
              {
                tableHead.map((item, index) => <div key={index} >
                  <div>{item}</div>
                </div>)
              }
            </div>
            }
            <div className='table_con'>
              <ul className='ul'>
                {listArr.length > 0 && listArr.map((item, idx) => <li key={idx}>
                  <div className='avatar phone'>
                      <i>{item.tick}</i>
                      <span>PRC-20</span>
                    </div>
                  <div>
                    <span className='phone'>Dotscription:</span>
                    <p>#{item.no}</p>
                  </div>
                  <div className={`${item.status == 'selled'&&'soldred'} ${item.status == 'finish'&&'green'}`}>
                    <span className='phone'>Event:</span>
                    {item.status=='selled'?'selling':item.status}
                  </div>
                  <div className='avatar webf'>
                    <i>{item.tick}</i>&nbsp;
                    <span>PRC-20</span>
                  </div>
                  <div><span className='phone'>Quantity:</span>{item.amt}</div>
                  <div><span className='phone'>Unit Price:</span>${item.usdPrice}</div>
                  <div><span className='phone'>Total Value:</span><p className='value_p'><div className='inline_div'>{item.totalPrice}DOT </div> ${item.usdTotalPrice}</p></div>
                  <div><span className='phone'>From:</span>{onAdd(item.from)}</div>
                  <div><span className='phone'>To:</span>{onAdd(item.to)}</div>
                  <div className='tcolor' ><span className='phone'>Time:</span>{onMomented(item.setupTime)}</div>
                </li>)}
                {
                  listArr.length == 0 && <div className='nodata'>
                    Yay! You have seen it all
                  </div>
                }
              </ul>

              {
                (total != null && total > limit) && <Pagination
                  activePage={page || 1}
                  prevPageText={''}
                  nextPageText={''}
                  itemsCountPerPage={limit}
                  totalItemsCount={total || 0}
                  pageRangeDisplayed={7}
                  onChange={(idx: number) => { onPagination(idx) }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hodlers;

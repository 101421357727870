import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import Hedaer from '../component/Hedaer';
import { goAdd, onAdd, onHttp, onMomented } from '../util/api';
import searchImg from '../assets/img/search.svg'


import '../assets/css/marketplace.less'
import MarketDetail from './MarketDetail';
import Footer from '../component/Footer';
import { SubstrateContext } from '../substrate-lib';
import Loading from '../component/Loading';


function InviterView() {
  const navigate = useNavigate();

  const { state: { address } } = useContext(SubstrateContext);

  const [page, setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null)

  const [isLoad, setisLoad] = useState<boolean>(false)

  const tableHead = [
    'Address',
    'Time',
  ]


  useEffect(()=>{
    if(address){
      getlist(1);
    }
  },[address])

  const getlist = async (from: Number) => {
    setisLoad(true)
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "address":address
    }, '/v2/Polkadot/MyInvList');
    setisLoad(false)
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      // if (res.data.total > limit) {
      //   setallpage(Math.ceil(res.data.total / limit))
      // }else{
      //   setallpage(1)
      // }
      setlistArr(res.data.list)
    }
  }


  // 分页
  const onPagination = (idx: number) => {
    setpage(idx)
    getlist(((idx - 1) * limit) + 1);
  }


  return (
    <>
      <div className='table_box market_box'>
        {
          listArr.length > 0 && <div className='table_head'>
          {
            tableHead.map((item, index) => <div key={index} >
              <div>{item}</div>
            </div>)
          }
        </div>
        }
        
        <div className='table_con'>
          
          <ul className='ul'>
            {!isLoad && listArr.length > 0 && listArr.map((item, idx) => <li key={idx} >
              
              <div className='copyli' onClick={()=>goAdd(item.address)}>
                <div>
                  {onAdd(item.address)}
                </div>
                
              </div>
              <div>{onMomented(item.time)}</div>
            </li>)}
            {isLoad && <Loading ></Loading>}
            
            {
              !isLoad && listArr.length == 0 && <div className='nodata'>
                Yay! You have seen it all
              </div>
            }
          </ul>

          {
            (total != null && total > limit) && <Pagination
              activePage={page || 1}
              prevPageText={''}
              nextPageText={''}
              itemsCountPerPage={limit}
              totalItemsCount={total || 0}
              pageRangeDisplayed={7}
              onChange={(idx: number) => { onPagination(idx) }}
            />
          }
        </div>
      </div>
    </>
  );
}

export default InviterView;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import { goAdd, onAdd, onHttp } from '../../util/api';
import goImg from '../../assets/img/go.svg'

function Activity(props:any) {

  const navigate = useNavigate();

  const did = 1
  const [page, setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null)

  const tableHead = [
    'Event',
    'Price',
    'From',
    'To',
    'Date',
  ]

  const getlist = async (from: Number) => {
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "did": did
    }, '/v2/Polkadot/Holders');
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setlistArr(res.data.list)
    }
  }

  useEffect(() => {
    getlist(1);
  }, []);


  // 分页
  const onPagination = (idx: number) => {
    setpage(idx)
    getlist(((idx - 1) * limit) + 1);
  }

  return (
    <>
      <div className='app_box content'>
        
        <div className='app_con'>
          <div className='table_box market_box' style={{ marginTop: 0 }}>
            <h3 className='h3clo'>Activity</h3>
            <div className='table_head'>
              {
                tableHead.map((item, index) => <div key={index} >
                  <div>{item}</div>
                </div>)
              }
            </div>
            <div className='table_con'>
              <ul className='ul'>
                {listArr.length > 0 && listArr.map((item, idx) => <li key={idx}>
                  <div>
                    --
                  </div>
                  <div>
                    --
                  </div>
                  <div>
                    --
                  </div>
                  <div>
                    --
                  </div>

                  <div>
                    --
                  </div>
                </li>)}
                {
                  listArr.length == 0 && <div className='nodata'>
                    Yay! You have seen it all
                  </div>
                }
              </ul>

              {
                (total != null && total > limit) && <Pagination
                  activePage={page || 1}
                  prevPageText={''}
                  nextPageText={''}
                  itemsCountPerPage={limit}
                  totalItemsCount={total || 0}
                  pageRangeDisplayed={7}
                  onChange={(idx: number) => { onPagination(idx) }}
                />
              }
            </div>
          </div>



        </div>
      </div>
    </>
  );
}

export default Activity;

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { networks } from '../util/api';
import logoimg from '../assets/img/logo.svg'
import rightImg from '../assets/img/isright.png'
import noneImg from '../assets/img/isnone.png'
import jtimg from '../assets/img/jt.svg'
import { SubstrateContext, connect } from '../substrate-lib'

import '../assets/css/head.less'


const Hedaer = () => {

  const { state: { apiState, accounts, socket, address }, dispatch } = useContext(SubstrateContext);
  const { state } = useContext(SubstrateContext);

  const location = useLocation()
  const navigate = useNavigate()

  const networksArr = networks.polkadot.rpcEndpoints
  const [netName, setnetName] = useState<string>('');
  const [addName, setaddName] = useState<string>('');

  const [isNetwork, setisNetwork] = useState<boolean>(false);
  const [isAdd, setisAdd] = useState<boolean>(false);

  useEffect(() => {
    if (address && accounts && accounts.length > 0) {
      let add = accounts.filter((item: any) => {
        return item.address == address
      })
      setaddName(add[0].meta.name)
    }
  }, [accounts, address]);

  useEffect(() => {
    if (socket) {
      let net = networksArr.filter((item) => {
        return item.key == socket
      })
      if (net && net.length > 0) {
        setnetName(net[0].label)
      }
    }
  }, [socket]);

  return <div className='hedaer_box'>
    <ScrollToTop></ScrollToTop>

    <p className='logo'>
     <img onClick={() => navigate('/')} src={logoimg} alt="logo" />
    </p>
    
    <div className='web'>
      <ul className='web_ul'>
        <li>
          <NavLink className={({isActive}) => { return isActive ? "ups" : ""; }} to="/">Launch</NavLink>
        </li>
        <li>
          <NavLink className={({isActive}) => { return (isActive || location.pathname == '/detail') ? "ups" : ""; }} to="/Token">Token</NavLink>
        </li>
        <li>
          <NavLink className={({isActive}) => { return (isActive || location.pathname == '/marketdetail' || location.pathname == '/listed') ? "ups" : ""; }} to="/Marketplace">Marketplace</NavLink>
        </li>
        <li>
          <NavLink className={({isActive}) => { return isActive ? "ups" : ""; }} to="/Profile">Profile</NavLink>
        </li>
      </ul>
    </div>
    
    <div>
      <div className='address_box net_box' onClick={() => setisNetwork(!isNetwork)}>
        <span>
          <img src={apiState == 'READY' ? rightImg : noneImg} alt="" />
          <i>{netName}</i>

        </span>
        <img className={isNetwork ? 'tranimg' : 'trandef'} src={jtimg} alt="" />
        {
          isNetwork && <div className='add_select'>
            {
              networksArr.map((item: any, index: any) => {
                return <p key={index} onClick={() => {
                  setisNetwork(false)
                  if (item.label == netName) return;
                  connect(state, dispatch, item.key)
                }}>
                  {item.label}
                </p>
              })
            }
          </div>
        }
      </div>

      {
        accounts && accounts.length > 0 && <div className='address_box' onClick={() => setisAdd(!isAdd)}>
          <span><i>{addName}</i></span>
          <img className={isAdd ? 'tranimg' : 'trandef'} src={jtimg} alt="" />
          {
            isAdd && <div className='add_select'>
              {
                accounts.map((item: any, index: any) => {
                  return <p key={index} onClick={() => {
                    setisAdd(false)
                    dispatch({ type: "setAddrerss", payload: item.address })
                  }}>
                    {item.meta.name}
                  </p>
                })
              }
            </div>
          }
        </div>
      }

      {
        !address && <button onClick={
            () => (window as any).location.reload(true)
          }>
          Connect
        </button>
      }

    </div>

  </div>
}

const ScrollToTop = (props: any) => {

  const { pathname } = useLocation();

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [pathname]);

  return props.children;

}

export default Hedaer;

import {useContext, useEffect , useState} from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate  } from 'react-router-dom';

import MintImg from '../assets/img/mintw.svg'
import loadImg from '../assets/img/loading.gif'

import { ForFailContent, ForSuccessfulContent } from '../component/ModalContent';
import TipModal from '../component/TipModal';

import '../assets/css/detail.less'
import { decimal, DotData, goAdd, goHash, onMomented, onWeb3FromSource2 } from '../util/api';
import Hodlers from './market/Hodlers';
import { SubstrateContext } from '../substrate-lib';
import { _extractEvents } from '../substrate-lib/SubstrateContext';
import Mint from './Mint';

const Detail  = () => {

    const navigate = useNavigate();
    
    let localtion = useLocation();

    const { state: { address , apiState , api  } } = useContext(SubstrateContext);

    const [objData,setobjData] = useState<any>(null)

    const [isFailOpen, setIsFailOpen] = useState<boolean>(false);
    const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
    const [text,settext] = useState<string>('')
    const [loading,setloading] = useState<boolean>(false)

    const [isMint, setisMint] = useState<boolean>(false);

    const [type, settype] = useState<string>('Holders')

    useEffect(()=>{
        if(localtion.state && localtion.state.dId){
            setobjData(localtion.state || {tick:''})
        }
    },[])


    const onMints = async()=>{
        if (!address) {
            toast('Please connect the address')
            return
        }
        setloading(true)
        settext('loading…')
        // let injected = await web3FromAddress(address).

        let tx: any;
        if (objData.fee == 0) {
            tx = api.tx.system.remarkWithEvent(DotData.Mint(objData.tick))
        } else {
            var txs = [
                api.tx.system.remarkWithEvent(DotData.Mint(objData.tick)),
                api.tx.balances.transfer(objData.feeTo, objData.fee * Math.pow(10, decimal)),
            ]
            tx = await api.tx.utility.batchAll(txs)
        }

        onWeb3FromSource2(tx, address, (result: any) => {
            if (result.status.isBroadcast) {
                settext('broadcast…')
            }

            if (result.status.isFinalized) {
                const { success, error }: any = _extractEvents(api, result)

                if (success) {
                    settext('success')
                    setIsSuccessfulOpen(true)
                    toast.success('success')
                }
                if (error) {
                    settext('failed')
                    setIsFailOpen(true)
                }
                setloading(false)
            }
            if (result.status.isInBlock) {
                settext("broadcast…")
            }
            if (result.status.isCancelled) {
                settext('cancellation')
                setloading(false)
            }
        }, (err: any) => {
            settext(err.message)
            setIsFailOpen(true)
            setloading(false)
        })
    }

    const isCompleted = ()=>{
        if(objData.minted){
            if((objData.minted/objData.supply) == 1 ){
                return true
            }
            return false
        }
        return false
    }

    return <>
        <div className='content'>
            {
                isFailOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => {setIsFailOpen(false)}}
                children={<ForFailContent title={text} title1="" />}
            />
            }
            {
                isSuccessfulOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => {setIsSuccessfulOpen(false)}}
                children={<ForSuccessfulContent title={text} title1="" />}
                />
            }
            <div className='onback' onClick={()=>navigate('/token')}>← back</div>
            {
                objData && <div className='detail_box detail_box_one'>
                    <div className='flex_sb'>
                        <h3>{objData.tick} <span>PRC-20</span></h3>
                        {
                            !isCompleted() && !objData.stopMinted && <button className={loading?'disbaleb_btn btn_lines':'btn_lines'} onClick={
                                ()=>{
                                    if(apiState == 'READY'){
                                        if(objData.tick == 'DOTS'){
                                            onMints()
                                            return
                                        }
                                        setisMint(true)
                                    }else{
                                        toast.error('Node not loaded')
                                    }
                                    
                                }
                            }>
                            {loading ? <img src={loadImg} alt="loading" style={{width:'16px'}} />:<img src={MintImg} alt="Mint" />}
                            {loading?text:'Mint'}</button>
                        }
                    </div>
                    <div className='flex_sb'>
                        <div className='progress'>
                            <div>
                                <i style={{width:( (objData.minted/objData.supply) * 100 + '%')}}></i>
                            </div>
                        </div>
                        <p>{objData.minted == 0?'0.00%':<>{Math.floor((objData.minted / objData.supply) * 10000) / 100}%</>}</p>
                    </div>
                </div>
            }
            {
                objData && <div className='detail_box'>
                    <div className='detail_con'>
                        <h4>Overview</h4>
                        <ul>
                            <li className='copyli'>
                                <p>Hash ID</p>
                                <div onClick={()=>goHash(objData.hash)}>{objData.hash} <i></i></div>
                            </li>
                            <li>
                                <p>Supply</p>
                                <div>{objData.supply}</div>
                            </li>
                            <li>
                                <p>Minted</p>
                                <div>{objData.minted}</div>
                            </li>
                            <li>
                                <p>Limit per mint</p>
                                <div className='red'>{objData.max}</div>
                            </li>
                            <li>
                                <p>Commission</p>
                                <div>{objData.fee} DOT</div>
                            </li>
                            <li className='copyli'>
                                <p>Deploy By</p>
                                <div onClick={()=>goAdd(objData.deployBy)}>{objData.deployBy} <i></i></div>
                                {/* <img onClick={()=>onCopy(objData.deployBy)} src="/copy.png" alt="copy" title='copy' /> */}
                            </li>
                            <li>
                                <p>Deploy Time</p>
                                <div>{onMomented(objData.deployTime)}</div>
                            </li>
                            {/* <li>
                                <p>Completed Time</p>
                                <div>{objData.Completed == 0?0:(moment(objData.deployTime*1000).format('YYYY/MM/DD HH:mm'))}</div>
                            </li> */}
                            <li>
                                <p>Holders</p>
                                <div>{objData.holders}</div>
                            </li>
                            <li>
                                <p>Total Transactions</p>
                                <div>{objData.transactions}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            {
                objData && type=='Holders' && <Hodlers did={objData.dId} tick={objData.tick} title={'Holders'} />
            }

            {
                objData && isMint && <Mint data={objData} onClose={()=>setisMint(false)} />
            }
        </div>

        
        
        
    </>;
}


export default Detail;
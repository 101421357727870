import { encodeAddress } from "@polkadot/keyring";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SubstrateContext } from "../substrate-lib";
import { _extractEvents } from "../substrate-lib/SubstrateContext";
import { decimal, DotData, onHttp, onWeb3FromSource2 } from "../util/api";
import { CheckoutHead } from "./ModalHead";
import { SuccessfulTip, FailTip } from "./SVGComponent";
import TipModal from "./TipModal";
import loadImg from '../assets/img/loading.gif'
import BigNumber from "bignumber.js"

// 售卖
const ForSaleContent = (props: any) => {

  const item = props.data;
  const { state: { address, api, apiState } } = useContext(SubstrateContext);
  const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
  const [text, settext] = useState<string>('')
  const [loading, setloading] = useState<boolean>(false)
  const [price,setprice] = useState<string>('');
  const [info,setinfo] = useState<any>(null)
  const [Total,setTotal] = useState<any>('')


  useEffect(()=>{
    init()
  },[])

  const init = async()=>{
    try {
      let res = await onHttp({did:item.did}, '/v2/Polkadot/tokenInfo');
      if(res && res.code==0){
        setinfo(res.data)
      }
      console.log(res)
    } catch (error) {
      setloading(false)
      settext('')
    }
  }

  const onSell = async () => {
    if(!info){
      toast.error('error')
      return
    }
    if (!address) {
      toast.error('Please connect the address')
      return
    }
    if (apiState != 'READY') {
      toast.error('Node not loaded')
      return
    }
    if(!price){
      toast.error('Please enter price')
      return
    }
    setloading(true)
    settext('loading…')
    let num1 = new BigNumber(price);
    let num2 = new BigNumber(item.amt);
    // console.log(num1.multipliedBy(num2).toString())
    let tx = api.tx.system.remarkWithEvent(DotData.Sell(item.tick, item.amt, item.no,num1.multipliedBy(num2).toString()))

    onWeb3FromSource2(tx, address, (result: any) => {
      if (result.status.isBroadcast) {
        settext('broadcast…')
      }

      if (result.status.isFinalized) {
        const { success, error }: any = _extractEvents(api, result)

        if (success) {
          settext('success')
          setIsSuccessfulOpen(true)
          toast.success('success')
        }
        if (error) {
          toast.error('failed')
        }
        setloading(false)
      }
      if (result.status.isInBlock) {
        settext("broadcast…")
      }
      if (result.status.isCancelled) {
        toast.error('cancellation')
        setloading(false)
      }
    }, (err: any) => {
      toast.error(err.message)
      setloading(false)
    })

    

  }

  useEffect(()=>{
    if(info){
      if(price){
        let all = parseFloat(price)  * item.amt
        let res =  all * (1-info.feeRate);
        if(res){
          res = Math.floor(res * Math.pow(10,8))/Math.pow(10,8)
        }
        setTotal(res)
      }else{
        setTotal('')
      }
    }
    

  },[price,info])

  return (
    <>
      {
        isSuccessfulOpen && <TipModal
          type="fail"
          isOpen={true}
          onClose={() => { setIsSuccessfulOpen(false); props.onClose() }}
          children={<ForSuccessfulContent title={text} title1="" />}
        />
      }

      <div className="sale_modal_main_content">
        <div className="sale_modal_top_content">
          <div className="sale_details_container">
            <div className="sale_content_left">
              <div className="head">{item.tick}</div>
              <div className="label_container">
                <span>PRC-20</span>
                <span>mint</span>
                <span>#{item.blockNumber}</span>
              </div>
              <div className="num">{item.amt}</div>
              <div className="tag">#{item.no}</div>
            </div>
            <div className="sale_content_right">
              <div>
                <span>Service Fee</span>
                <span>{info?(info.feeRate * 100):0} %</span>
              </div>
              {/* <div>
                <span>Creator Royalty</span>
                <span>--</span>
              </div> */}
              <div>
                <span>Floor Price</span>
                <span>{info?(info.floorPrice):'--'} DOT</span>
              </div>
            </div>
          </div>
          <div className="sale_input_container">
            <span>Unit Price</span>
            <input type="text" placeholder='' value={price} onChange={(ev)=>{setprice(ev.target.value.replace(/^\D*(\d*(?:\.\d{0,8})?).*$/g, '$1'))}} />
            <span>DOT</span>
          </div>
        </div>
        <Divider marginX="-50px" marginTop="45px" marginBottom="35px" />
        <div className="sale_modal_bottom_content">
          <div className="total_price_container">
            <div>Total Revenue:</div>
            <div className="total_price_detail">
              <span>{Total?Total:'--'} DOT</span>
              {/* <span>$0.00</span> */}
            </div>
          </div>
          <button className={loading ? 'disbaleb_btn' : ''} disabled={loading} onClick={onSell}>{loading ? text : 'listing'}</button>
        </div>
      </div>

    </>

  );
};

// buy购买
const ForCheckoutContent = (props: any) => {
  const item = props.data;
  console.log(item)
  const { state: { address, api, apiState } } = useContext(SubstrateContext);
  const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
  const [text, settext] = useState<string>('')
  const [loading, setloading] = useState<boolean>(false)

  const [info,setinfo] = useState<any>(null)
  const [Total,setTotal] = useState<any>('')


  useEffect(()=>{
    init()
  },[])

  const init = async()=>{
    try {
      let res = await onHttp({did:item.did}, '/v2/Polkadot/tokenInfo');
      if(res && res.code==0){
        setinfo(res.data)
      }
      console.log(res)
    } catch (error) {
      setloading(false)
      settext('')
    }
  }

  const UnLock = async()=>{
   await onHttp({}, '/v2/Polkadot/UnLock?OrderId='+item.orderId+'&Address='+address)
  }


  const onBuy = async () => {
    if (!address) {
      toast.error('Please connect the address')
      return
    }
    if (apiState != 'READY') {
      toast.error('Node not loaded')
      return
    }
    if(!info){
      toast.error('Reading. Try again later')
      return
    }

    setloading(true)
    settext('loading…')

    try {
      // { data: { free: balance} }
      let { data: { free: balance} } = await api.query.system.account(item.seller);
      if(!balance || Number(balance) == 0){
        toast.error('The seller has insufficient balance and is not an active account.')
        setloading(false)
        settext('')
        return
      }
      let sender = Number(balance) / Math.pow(10,decimal);
      // console.log(sender)
      if(!sender || sender < 1){
        toast.error('The seller has insufficient balance and is not an active account.')
        setloading(false)
        settext('')
        return
      }

      let res = await onHttp({}, '/v2/Polkadot/GetLock?OrderId='+item.orderId+'&Address='+address)
      if (res && res.code == 0) {

        let num1 = new BigNumber(item.totalPrice)
        let num2 = new BigNumber(info.feeRate)
        let num3 = new BigNumber(Math.pow(10,decimal))
        let num4 = new BigNumber(1 - info.feeRate)
        console.log('-----------buy')
        // console.log(num1.multipliedBy(num2).multipliedBy(num3).multipliedBy(2).toString())
        // console.log(num1.multipliedBy(num3).multipliedBy(num4).toString())

        let objs1 = parseInt(num1.multipliedBy(num2).multipliedBy(num3).multipliedBy(2).toString())
        let objs2 = parseInt(num1.multipliedBy(num3).multipliedBy(num4).toString())
        console.log(objs1)
        console.log(objs2)
        // return
        debugger
        var txs = [
          api.tx.system.remarkWithEvent(DotData.Buy(item.tick, item.amt, item.no)),
          api.tx.balances.transfer(info.address, objs1),
          api.tx.balances.transfer(item.seller, objs2)
        ]
        let tx = await api.tx.utility.batchAll(txs)
        onWeb3FromSource2(tx, address, (result: any) => {
          if (result.status.isBroadcast) {
            settext('broadcast…')
          }

          if (result.status.isFinalized) {
            const { success, error }: any = _extractEvents(api, result)

            if (success) {
              settext('success')
              setIsSuccessfulOpen(true)
              toast.success('success')
            }
            if (error) {
              console.log(error)
              toast.error('failed')
            }
            setloading(false)
          }
          if (result.status.isInBlock) {
            settext("broadcast…")
          }
          if (result.status.isCancelled) {
            toast.error('cancellation')
            setloading(false)
          }
        }, (err: any) => {
          console.log(err)
          UnLock()
          toast.error(err.message)
          setloading(false)
        })
      } else {
        console.log(res)
        toast.error(res && res.message)
        setloading(false)
        settext('')
      }
    } catch (error: any) {
      console.log(error)
      settext('')
      setloading(false)
      toast.error(error.message)
    }


  }

  return (
    <>
      {
        isSuccessfulOpen && <TipModal
          type="fail"
          isOpen={true}
          onClose={() => { setIsSuccessfulOpen(false);props.onClose() }}
          children={<ForSuccessfulContent title={text} title1="" />}
        />
      }

      <div className="checkout_modal_content checkout_modal_buy">
          <div className="checkout_content_left">
            <div className="left_head">{item.tick}</div>
            <div className="left_label_container">
              <span>PRC-20</span>
              <span>mint</span>
              <span>#{item.height}</span>
            </div>
            <div className="left_num">{item.amt}</div>
            <div className="left_price">
              <span className="left_price_num">${item.usdPrice} </span>
              <span className="left_price_dot"> / {item.tick}</span>
            </div>
          </div>

          <div className="checkout_content_right">
            <div className="right_tag">#{item.no}</div>
            <div className="right_price_content">
              <span>Price:</span>
              <span>{item.totalPrice} DOT</span>
            </div>
            <div className="right_price_content">
                <span>Service Fee</span>
                <span>{info?(info.feeRate * 100):0} %</span>
              </div>
            <div className="right_pay_content">
              <div>You pay:</div>
              <div className="right_pay_content_details">
                <span>{info?(BigNumber(item.totalPrice).multipliedBy(new BigNumber(1+info.feeRate))).toFormat():'--'} DOT</span>
                <span>${info?( Math.floor(item.usdTotalPrice * (1+info.feeRate) *100) /100 ):'--'}</span>
              </div>
            </div>
            <button className={loading ? 'disbaleb_btn' : ''} disabled={loading} onClick={onBuy}>{loading ? text : 'BUY'}</button>
          </div>

      </div>

    </>
  );
};

// 转账
const ForTranferContent = (props: any) => {
  const item = props.data;
  const { state: { address, api, apiState } } = useContext(SubstrateContext);
  const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
  const [text, settext] = useState<string>('')
  const [loading, setloading] = useState<boolean>(false)

  const [toadd, settoadd] = useState<string>('')
  const [tranAdd, settranAdd] = useState<string>('');

  const onTransfer = async () => {
    if (!address) {
      toast.error('Please connect the address')
      return
    }
    if (apiState != 'READY') {
      toast.error('Node not loaded')
      return
    }

    setloading(true)
    settext('loading…')
    let tx = api.tx.system.remarkWithEvent(DotData.Transfer(tranAdd, item.tick, item.amt, item.no))

    onWeb3FromSource2(tx, address, (result: any) => {
      if (result.status.isBroadcast) {
        settext('broadcast…')
      }

      if (result.status.isFinalized) {
        const { success, error }: any = _extractEvents(api, result)

        if (success) {
          settext('success')
          setIsSuccessfulOpen(true)
          toast.success('success')
        }
        if (error) {
          toast.error('failed')
        }
        setloading(false)
        settoadd('')
        settranAdd('')
      }
      if (result.status.isInBlock) {
        settext("broadcast…")
      }
      if (result.status.isCancelled) {
        toast.error('cancellation')
        setloading(false)
      }
    }, (err: any) => {
      toast.error(err.message)
      setloading(false)
    })

  }


  return (
    <>
      {
        isSuccessfulOpen && <TipModal
          type="fail"
          isOpen={true}
          onClose={() => { setIsSuccessfulOpen(false);props.onClose() }}
          children={<ForSuccessfulContent title={text} title1="" />}
        />
      }

      <div className="tranfer_main_modal_content">
        <div className="tranfer_modal_content">
          <div className="tranfer_content">
            <div className="head">{item.tick}</div>
            <div className="label_container">
              <span>PRC-20</span>
              <span>mint</span>
              <span>#{item.blockNumber}</span>
            </div>
            <div className="num">{item.amt}</div>
            {/* <div className="left_price">
          <span className="left_price_num">$14.5682 </span>
          <span className="left_price_dot"> / DOTS</span>
        </div> */}
            <div className="tag">#{item.no}</div>
          </div>
          <div className="tranfer_content_input">
            <span className={tranAdd == 'Invalid Address' ? 'red' : ''}>{tranAdd}</span>
            <input type="text" value={toadd} placeholder="Address" onChange={(ev) => {
              try {
                let add = encodeAddress(ev.target.value, 0)
                if (add) {
                  settranAdd(add)
                }
              } catch (error) {
                settranAdd('Invalid Address')
              }
              settoadd(ev.target.value)
            }
            } />
          </div>

        </div>
        <button
          className={(tranAdd == 'Invalid Address' || tranAdd == '' || loading) ? 'disbaleb_btn' : ''}
          disabled={(tranAdd == 'Invalid Address' || tranAdd == '' || loading)}
          onClick={onTransfer}>
          {/* {loading ? text : 'Transfer'} */}
          {loading ? <><img src={loadImg} alt="loading" style={{ width: '16px' }} />{text}</> : 'Transfer'}
        </button>
      </div>


    </>
  );
};

const ForSuccessfulContent = ({ title, title1 }: any) => {
  return (
    <div className="successful_modal_content">
      <SuccessfulTip />
      <div className="successful_text">
        {/* <div>Congratulations!</div> */}
        <div>{title}</div>
        <div>{title1}</div>
      </div>
    </div>
  );
};

const ForFailContent = ({ title, title1 }: any) => {
  return (
    <div className="fail_modal_content">
      <FailTip />
      <div className="fail_text">
        {/* <div>Error! </div> */}
        <p>{title}</p>
        <p>{title1}</p>
      </div>
    </div>
  );
};

interface DividerProps {
  marginTop?: string;
  marginX?: string;
  marginBottom?: string;
}

const Divider: React.FC<DividerProps> = (props) => {
  let { marginTop = "0", marginX = "0", marginBottom = "0" } = props;
  return (
    <hr
      style={{
        margin: `${marginTop} ${marginX} ${marginBottom}`,
        borderTop: "1px solid #000",
        background: "rgba(0, 0, 0, 0.10)",
        opacity: "0.1",
      }}
    />
  );
};

export {
  ForSaleContent,
  ForCheckoutContent,
  ForTranferContent,
  ForSuccessfulContent,
  ForFailContent,
};

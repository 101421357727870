import book from '../assets/img/book.svg'
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
  
  const location = useLocation()

  return <div className='footer_box'>
    <p>
        Media inquiries for DotMarket - Contact <span>
        <a href="https://twitter.com/inscription_dot" target={'_blank'}>@DotMarket</a>
        <a href="https://dotscription.gitbook.io/dotscription/" target={'_blank'}><img src={book} alt="" /></a>
      </span>
    </p>

    <div className='menubox phone'>
      <div className='menu_con'>
        <ul>
          <li>
            <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/">Launch</NavLink>
          </li>
          <li>
            <NavLink className={({isActive}) => { return (isActive || location.pathname == '/detail') ? "ups" : ""; }} to="/Token">Token</NavLink>
          </li>
          <li>
            <NavLink className={({isActive}) => { return (isActive || location.pathname == '/marketdetail' || location.pathname == '/listed') ? "ups" : ""; }} to="/Marketplace">Market</NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/Profile">Profile</NavLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
}

export default Footer;
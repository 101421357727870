
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BigNumber from "bignumber.js"

import jtimg from '../assets/img/bottom.svg'

import '../assets/css/marketplace.less'
import { SubstrateContext } from '../substrate-lib';
import { onAdd, onMomented } from '../util/api';
import Activity from './market/Activity';

function Marketplace() {
  const navigate = useNavigate();

  let localtion = useLocation();

  const [detailObj, setdetailObj] = useState<any>(null)
  const { state: { address } } = useContext(SubstrateContext);

  useEffect(() => {
    console.log(localtion.state)
    if (localtion.state) {
      setdetailObj(localtion.state || { tick: '' })
    }

  }, [])

  // const getDetails = async (tick:string) => {
  //   const res = await onHttp({
  //     "tick": tick,
  //   }, '/v2/Polkadot/MarketDetail');
  //   console.log(res)
  //   if (res && res.code == 0) {
  //     setdetailObj(res.data)
  //   }
  // }

  const tableHead = [
    'Listed',
    'Holders',
    'Orders',
    'My Orders',
  ]

  return (
    <>
      <div className='content'>
        <div className='markde_detail_top'>
          <h3 onClick={() => navigate(-1)}><img src={jtimg} alt="" />Listed</h3>
          <div className='Listed_box'>
            {
              detailObj && <div className='list_card'>
              <ul className='list_ul'>
                <li>
                  <div>
                    <h3>{detailObj.tick}</h3>
                    <div className='hodlers_border'>
                      <span>PRC-20</span>
                      <span>mint</span>
                      <span>#{detailObj.height}</span>
                    </div>
                    <p className='hodlers_p'>{detailObj.amt}</p>
                    <p className='hodlers_p1'><i>${detailObj.usdPrice}</i> / {detailObj.tick}</p>
                  </div>

                </li>
              </ul>
            </div>
            }
            
            {
              detailObj && <div>
                <h5>#{detailObj.no}</h5>
                <div  className='listed_div1'>
                  {/* <span>Owner: <i>{onAdd(address)}</i></span> */}
                  <span>Creator: <i>{onAdd(address)}</i></span>
                  <span>Seller: <i>{onAdd(detailObj.seller)}</i></span>
                  <span>Created: <i>--{onMomented(detailObj.listed)}</i></span>
                  <span>View: <i><a href='https://polkadot.subscan.io' target="_blance">https://polkadot.subscan.io</a></i></span>
                </div>

                <div className='listed_div2'>
                  <span>Current Price: <i>{detailObj.amt}</i></span>
                </div>
              </div>
            }

          </div>
        </div>
        {
          detailObj && <div className='markde_detail_con'>
            <Activity />
          </div>
        }

      </div>

    </>
  );
}

export default Marketplace;

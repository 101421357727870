import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import { onHttp } from '../util/api';
import searchImg from '../assets/img/search.svg'
import rightImg from '../assets/img/right.svg'
import BigNumber from "bignumber.js"

import '../assets/css/marketplace.less'
import Loading from '../component/Loading';
// import MarketDetail from './MarketDetail';

function Marketplace() {
  const navigate = useNavigate();

  const [page,setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total,settotal] = useState<number|null>(null)
  const [name,setname] = useState<string>('');
  const [isLoad, setisLoad] = useState<boolean>(false)

  // const [detailObj,setDetailObj] = useState<any>(null);
  
  const tableHead = [
    'Collection',
    'Floor Price',
    'Unit Price',
    '24h Volume',
    '24h Sales',
    'Holders',
    'Total Volume',
    'Total Sales',
    'Market Cap',
    'Listed',
  ]

  const onKeyDown = (event: any,names?:string) => {
    let e: any = event ? event : (window.event ? window.event : null);
    if(names){
      if (e.keyCode == 13 && name) {
        getlist(1)
      }
    }
  }

  const getlist = async (from: Number,falg?:boolean) => {
    setisLoad(true)
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "tick":falg?'':name
    }, '/v2/Polkadot/Markets');
    setisLoad(false)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setlistArr(res.data.list)
    }
  }

  useEffect(() => {
    getlist(1);
  }, []);

  // 分页
  const onPagination = (idx:number)=>{
    setpage(idx)
    getlist(((idx - 1)*limit) + 1);
  }

    
  return (
    <>
        <div className='app_box content'>
          
          <div className='app_con marketplace_con'>
            <div className='flex_sb web'>
              <h3>Trending Tokens</h3>
              <div className='input_box'>
                <input type="text" value={name} onKeyDown={(ev) => onKeyDown(ev,'name')} placeholder='Dots' onChange={(ev) => {
                  if(!ev.target.value){
                    getlist(1,true)
                  }
                  setname(ev.target.value)
                }} />
                <img onClick={()=>getlist(1)} src={searchImg} alt="search" />
              </div>
            </div>
            

            <div className='table_box market_box'>
              <div className='table_head webf'>
                {
                  tableHead.map((item, index) => <div key={index} >
                    <div>{item}</div>
                  </div>)
                }
              </div>
              <div className='table_con'>
                <ul className='ul'>
                  {isLoad && <Loading />}
                  {!isLoad && listArr.length > 0 && listArr.map((item, idx) => <li key={idx} onClick={() => {navigate('/marketdetail', { state: item })}}>
                    <div className='avatar'>
                      <i>{item.tick}</i>
                      <span>PRC-20</span>
                      {item.official && <><img src={rightImg} alt="" /></>}
                    </div>
                    <div><span className='phone'>Floor Price:</span> 
                      {BigNumber(item.floorPrice).toFormat()}
                    </div>
                    <div><span className='phone'>Unit Price:</span> ${BigNumber(item.unitPrice).toFormat()}</div>
                    <div><span className='phone'>24h Volume:</span>{item.dayVolume}</div>
                    <div><span className='phone'>24h Sales:</span>{item.daySales}</div>
                    <div><span className='phone'>Holders:</span>{item.owners}</div>
                    <div><span className='phone'>Total Volume:</span>{item.totalVolume}</div>
                    <div><span className='phone'>Total Sales:</span>{item.totalSales}</div>
                    <div><span className='phone'>Market Cap:</span> <p>$ {BigNumber(item.marketCap).toFormat()}</p> </div>
                    <div><span className='phone'>Listed:</span>{item.listed}</div>
                  </li>)}
                  {
                    !isLoad && listArr.length == 0 && <div className='nodata'>
                      Yay! You have seen it all
                    </div>
                  }
                </ul>
                
                {
                  (total !=null && total > limit ) && <Pagination
                    activePage={page||1}
                    prevPageText={''}
                    nextPageText={''}
                    itemsCountPerPage={limit}
                    totalItemsCount={total || 0}
                    pageRangeDisplayed={7}
                    onChange={(idx: number)=>{onPagination(idx)}}
                  />
                }
              </div>
            </div>
          </div>
          
        </div>


    </>
  );
}

export default Marketplace;


import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BigNumber from "bignumber.js"

import jtimg from '../assets/img/bottom.svg'
import rtimg from '../assets/img/right.svg'
import Hodlers from './market/Hodlers';
import Orders from './market/Orders';
import MyOrders from './market/MyOrders';
import Listed from './market/Listed';

import '../assets/css/marketplace.less'

function Marketplace() {
  const navigate = useNavigate();

  let localtion = useLocation();
  
  const [type,settype] = useState<string>('Listed')
  const [detailObj,setdetailObj] = useState<any>(null)

  useEffect(()=>{
    console.log(localtion.state)
    if(localtion.state && localtion.state.dId){
      setdetailObj(localtion.state || {tick:''})
    }
    
  },[])

  // const getDetails = async (tick:string) => {
  //   const res = await onHttp({
  //     "tick": tick,
  //   }, '/v2/Polkadot/MarketDetail');
  //   console.log(res)
  //   if (res && res.code == 0) {
  //     setdetailObj(res.data)
  //   }
  // }

  const tableHead = [
    'Listed',
    'Holders',
    'Orders',
    'My Orders',
  ]
    
  return (
    <>
        <div  className='content'>
        <div className='markde_detail_top'>
          <h3 onClick={()=>navigate(-1)}><img src={jtimg} alt="" /> Marketplace</h3>
          <div>
            {/* <img src={avatarImg} alt="" /> */}
            {
              detailObj && <div>
              <h5>{detailObj.tick} <span>PRC-20</span> {detailObj.official && <img src={rtimg} alt="" />}</h5>
              <div>
                <span>Floor <i>{BigNumber(detailObj.floorPrice).toFormat()} DOT</i></span>
                {/* <span>Unit Price <i>{detailObj.unitPrice}</i></span> */}
                <span>Total Vol <i>{detailObj.totalVolume}</i></span>
                <span>Holders <i>{detailObj.owners}</i></span>
                <span>Listed <i>{detailObj.listed}</i></span>
                <span>Total Supply <i>{BigNumber(detailObj.supply).toFormat()}</i></span>
              </div>
            </div>
            }
            
          </div>
        </div>
          {
            detailObj && <div className='markde_detail_con'>
            <div className='markde_detail_tab'>
              {
                tableHead.map((item, index) => <span className={item == type?'ups':''} onClick={()=>{settype(item)}} key={index} >
                  {item}
                </span>)
              }
            </div>
            
            { type == 'Listed' && <Listed did={detailObj.dId} />}
            { type == 'Holders' && <Hodlers did={detailObj.dId} tick={detailObj.tick} />}
            { type == 'Orders' && <Orders did={detailObj.dId} />}
            { type == 'My Orders' && <MyOrders did={ detailObj.dId } />}
            
          </div>
          }
        
        </div>

    </>
  );
}

export default Marketplace;

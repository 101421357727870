import { web3FromAddress } from "@polkadot/extension-dapp"
import moment from "moment"

const headers = {
  'Content-Type': 'application/json'
}


// const host = 'http://192.168.0.101:10880'
const host = ''


export const networks = {
  'polkadot': {
      rpcEndpoint: 'wss://rpc.polkadot.io',
      ss58Format: 0,
      decimals: 10,
      rpcEndpoints: [
          // {
          //   label:'Recommended',
          //   key:'wss://late-palpable-wave.dot-mainnet.quiknode.pro/81b73b00d8f67fbe02c4130a64b15fa0b3fd0d10/'
          // },
          // {
          //     label: 'via Test',
          //     key: 'ws://13.70.4.196:7733/'
          // },
          {
              label: 'via On-finality',
              key: 'wss://polkadot.api.onfinality.io/public-ws'
          },
          {
              label: 'via Dwellir',
              key: 'wss://polkadot-rpc.dwellir.com'
          },
          {
              label: 'via Parity',
              key: 'wss://rpc.polkadot.io'
          },
          {
              label: 'via Pinknode',
              key: 'wss://public-rpc.pinknode.io/polkadot'
          },
          {
              label: 'via IBP-GeoDNS1',
              key: 'wss://rpc.ibp.network/polkadot'
          },
          {
              label: 'via IBP-GeoDNS2',
              key: 'wss://rpc.dotters.network/polkadot'
          },
          {
              label: 'via RadiumBlock',
              key: 'wss://polkadot.public.curie.radiumblock.co/ws'
          },
          {
              label: 'via LuckyFriday',
              key: 'wss://rpc-polkadot.luckyfriday.io'
          },
      ]
  },
};
export const decimal = 10

export const getApi = <T>(url: string, init?: RequestInit): Promise<T> => {
  return fetch(host+url, init)
    .then(response => {
      // console.log(response)
      if(response.status == 500){
        // console.log(response.json())
      }
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json() as Promise<T>
    })
}

export const onHttp = async(parms: any,url:string)=>{
  
  let obj:any = {
      method: 'post',
      headers: headers
  }
  if(parms){
      obj.body = JSON.stringify(parms)
  }
  try {
      const response = await fetch(host+url,obj);
      if(response.status == 200){
          return response.json();
      }else{
          console.log('network error')
      }
  } catch(err) {
      console.log(err);
  }
}

export const onAdd = (address:string) => {
  if (address && address.length > 10) {
    return address.slice(0, 4) + '....' + address.substr(-4, 4)
  } else {
    return address
  }
}

export const onMomented = (item:any)=>{
  if(item){
    let time = moment.utc(item * 1000)
    return time.local().format('YYYY-MM-DD HH:mm')
  }
  return ''
}

export async function copyPlaintext(text: string): Promise<boolean> {
  try {
      if (navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(text);
          return true;
      } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;

          // Avoid scrolling to bottom
          textArea.style.top = "0";
          textArea.style.left = "0";
          textArea.style.position = "fixed";

          document.body.appendChild(textArea);
          const selection:any = document.getSelection();
          const range = document.createRange();
          // range.selectNodeContents(textArea);
          range.selectNode(textArea);
          selection.removeAllRanges();
          selection.addRange(range);

          const successful = document.execCommand("copy");
          selection.removeAllRanges();
          document.body.removeChild(textArea);
          return successful;
      }
  } catch (e) {
      console.log("copyPlaintext failed", e);
  }
  return false;
}

// 方法
class DOTScriptionTemplate {
  public Delopy(max: number, tick: string, lim: number,gas:string): string {
      return `data:,{"p":"prc-20","op":"deploy","max":"${max}","tick":"${tick}","lim":"${lim}","gas":"${gas}"}`
  }
  // data:,{"p":"prc-20","op":"deploy","max":"21000000","tick":"usdt","lim":"1000","gas":0.1} 
  // data:,{"p":"prc-20","op":"deploy","max":"${max}","tick":"${tick}","lim":"${lim}"}
  public Mint(tick: string): string {
      return `data:,{"p":"prc-20","op":"mint","tick":"${tick}"}`
  }
  public Transfer(to: string, tick: string, amt: number, no: number): string {
      return `data:,{"p":"prc-20","op":"transfer","tick":"${tick}","amt":"${amt}","to":"${to}","no":"${no}"}`
  }

  public Buy(tick: string, amt: number, no: number): string {
      return `data:,{"p":"prc-20","op":"buy","tick":"${tick}","amt":"${amt}","no":"${no}"}`
  }
  public Sell(tick: string, amt: number, no: number, price:any): string {
    return `data:,{"p":"prc-20","op":"sell","tick":"${tick}","amt":"${amt}","no":"${no}","price":"${price}"}`
  }
  
  public Invite(address: string): string {
      return `data:,{"p":"prc-20","inviter":"${address}"}`
      
  }
  public Exchange(tick: string, amt: number, no: number): string {
      return `data:,{"p":"prc-20","op":"transfer","tick":"${tick}","amt":"${amt}","no":"${no}"}`
  }
}
export const DotData= new DOTScriptionTemplate();

// web3FromAddress(sender)
export const onWeb3FromSource = (tx:any,source:any,accountPair:any,api:any,resFun:any,errFun:any,address?:string)=>{
  web3FromAddress(address || '').then(function (injected:any) {
    console.log(injected)
    // debugger
    tx.signAndSend(address, { signer: injected.signer }, (result: any) => {
      resFun(result)
    }).catch(function (err:any) {
      errFun(err)
    });
  })
}

export const onWeb3FromSource2 = (tx:any,address:string,resFun:any,errFun:any)=>{
  web3FromAddress(address).then(function (injected:any) {
    tx.signAndSend(address, { signer: injected.signer }, (result: any) => {
      resFun(result)
    }).catch(function (err:any) {
      errFun(err)
    });
  })
}

export const goAdd = (address:string)=>{
  let url = 'https://polkadot.subscan.io/account/'+ address
  window.open(url, "_blank");
}

export const goHash = (hash:string)=>{
  let url = 'https://polkadot.subscan.io/extrinsic/'+ hash
  window.open(url, "_blank");
}


import { useContext, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { loadAccounts, SubstrateContext } from '../../substrate-lib';
import { onAdd, onHttp, onMomented } from '../../util/api';
import noImg from '../../assets/img/nowallet.svg'


function Hodlers(props:any) {
  const did = props.did
  const [page, setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null)

  const { state: { address }, dispatch } = useContext(SubstrateContext);
  const { state } = useContext(SubstrateContext);

  const tableHead = [
    'Dotscription',
    'Event',
    'Collecting',
    'Quantity',
    'Unit Price',
    'Total Value',
    'From',
    'To',
    'Time',
  ]

  const getlist = async (from: Number) => {
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "address":address,
      "did": did,
    }, '/v2/Polkadot/MarketMyOrders');
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setlistArr(res.data.list)
    }
  }

  useEffect(() => {
    if(address){
      getlist(1);
    }
   
  }, [address]);


  // 分页
  const onPagination = (idx: number) => {
    setpage(idx)
    getlist(((idx - 1) * limit) + 1);
  }

  return (
    <>
      <div className='app_box content'>
        <div className='app_con orders_box'>
          <div className='table_box market_box' style={{ marginTop: 0 }}>

            {
              listArr.length > 0 && <div className='table_head webf'>
              {
                tableHead.map((item, index) => <div key={index} >
                  <div>{item}</div>
                </div>)
              }
            </div>
            }
            

            <div className='table_con'>
              {
                address && 
                <ul className='ul'>
                  {listArr.length > 0 && listArr.map((item, idx) => <li key={idx} >
                      <div className='avatar phone'>
                          <i>{item.tick}</i>
                          <span>PRC-20</span>
                        </div>
                      <div>
                        <span className='phone'>Dotscription:</span>
                        <p>#{item.no}</p>
                      </div>
                      <div className={`${item.status == 'selled'&&'soldred'} ${item.status == 'finish'&&'green'}`}>
                        <span className='phone'>Event:</span>
                        {item.status=='selled'?'selling':item.status}
                      </div>
                      <div className='avatar webf'>
                        <i>{item.tick}</i>&nbsp;
                        <span>PRC-20</span>
                      </div>
                      <div><span className='phone'>Quantity:</span>{item.amt}</div>
                      <div><span className='phone'>Unit Price:</span>${item.usdPrice}</div>
                      <div><span className='phone'>Total Value:</span><p className='value_p'><div className='inline_div'>{item.totalPrice}DOT </div> ${item.usdTotalPrice}</p></div>
                      <div><span className='phone'>From:</span>{onAdd(item.from)}</div>
                      <div><span className='phone'>To:</span>{onAdd(item.to)}</div>
                      <div className='tcolor'><span className='phone'>Time:</span>{onMomented(item.setupTime)}</div>
                    </li>)}
                  {
                    listArr.length == 0 && <div className='nodata'>
                      Yay! You have seen it all
                    </div>
                  }
                </ul>
              }
              {
                !address && <div className='nodata no_wallet'>
                  <img src={noImg} alt="" />
                  <p>Yay! Wallet is disconnected</p>
                  <button onClick={()=> (window as any).location.reload(true) }>CONNECT WALLET</button>
                </div>
              }
              

              {
                (total != null && total > limit) && <Pagination
                  activePage={page || 1}
                  prevPageText={''}
                  nextPageText={''}
                  itemsCountPerPage={limit}
                  totalItemsCount={total || 0}
                  pageRangeDisplayed={7}
                  onChange={(idx: number) => { onPagination(idx) }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hodlers;

import { Fragment, useEffect, useState, useMemo } from "react";
import { CloseButton } from "./SVGComponent";
import "../assets/css/tipModal.less";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  head?: React.ReactNode; 
  type?: 'successful' | 'fail' | 'forSale' | 'forTranfer' | 'forCheckout';
}

type ModalStyles = {
  successful: {
    width: string;
    height: string;
    padding: string
  },
  fail: {
    width: string;
    height: string;
    padding: string
  },
  forSale: any,
  forCheckout: any,
  forTranfer: any
};

const TipModal :React.FC<ModalProps> = (props: any) => {

  let { isOpen, onClose, children, head, type = "successful" } = props;

  if (!isOpen) {
    return null;
  }

  const handleClickOverlay = (e: any) => {
    if (e.target.id === "overlay") {
      e.stopPropagation();
      // onClose();
    }
  };
  const modalStyle :ModalStyles = {
    "successful": {
      width: '375px',
      height: '375px',
      padding: "0"
    },
    "fail": {
      width: '375px',
      height: '375px',
      padding: "0"
    },
    "forSale": {
      
    },
    "forCheckout": {
      
    },
    "forTranfer": {
     
    }
  }
  const currentStyle = modalStyle[type as keyof ModalStyles];

  return (
    <Fragment>
      <div
        id="overlay"
        onClick={handleClickOverlay}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
        }}
      >
        <div
          id="tip_main_modal"
          style={{
            position: "relative",
            // width: "50%",
            // padding: "40px 50px 50px",
            borderRadius: "20px",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            ...currentStyle
          }}
        >
          <div className="modal_header">
            {head}{" "}
            <div
              onClick={onClose}
              className="closebtn"
            >
              <CloseButton />
            </div>
          </div>
          {children}
        </div>
      </div>
    </Fragment>
  );
};
export default TipModal;

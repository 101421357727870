import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import Pagination from "react-js-pagination";

import searchImg from '../assets/img/search.svg'
import MintImg from '../assets/img/mint.svg'
import rightImg from '../assets/img/right.svg'
import iconIMG from '../assets/img/icon.svg'



import { onHttp, onMomented } from '../util/api';
import Deploy from '../pages/Deploy';
import SearchView from '../pages/SearchView';

import '../assets/css/app.less'
import '../assets/css/phone.less';
import { encodeAddress } from '@polkadot/keyring';
import Loading from '../component/Loading';


function Token() {

  const navigate = useNavigate();
  
  let localtion = useLocation();
  const [searchAdd, setsearchAdd] = useState<string>('');

  const [page,setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total,settotal] = useState<number|null>(null)

  const [isSearch,setisSearch] = useState<boolean>(false)
  const [searchArr, setsearchArr] = useState<Array<any>>([]);

  const [isDeploy,setisDeploy] = useState<boolean>(false)
  const [isLoad, setisLoad] = useState<boolean>(false)
  
  const tableHead = [
    'Token',
    'Deploy Time',
    'Progress',
    'Holders',
    'Transactions'
  ]

  useEffect(() => {
    getlist(1);
    
    if(localtion.state){
      setsearchAdd(localtion.state)
      onSearch(localtion.state)
    }
  }, []);


  const onKeyDown = (event: any) => {
    let e: any = event ? event : (window.event ? window.event : null);
    if (e.keyCode == 13 && searchAdd) {
      onSearch()
    }
  }

  const onSearch = (adds?:string)=>{
    if(adds){
      setisSearch(true)
      getAddressList(adds)
    }
    if(!searchAdd){
      return
    }
    
    try {
      let add = encodeAddress(searchAdd)
      if(add){
        setisSearch(true)
        getAddressList()
      }else{
        setisSearch(false)
        getlist(1)
      }
    } catch (error) {
      setisSearch(false)
      getlist(1)
    }
    
    
  }

  const getlist = async (from: Number,falg?:boolean) => {
    setisLoad(true)
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "name": falg?'':searchAdd
    }, '/v2/Polkadot/List');
    console.log(res)
    setisLoad(false)
    if (res && res.code == 0) {
      settotal(res.data.total)
      // if (res.data.total > limit) {
      //   setallpage(Math.ceil(res.data.total / limit))
      // }else{
      //   setallpage(1)
      // }
      setlistArr(res.data.list)
    }
  }

  const getAddressList = async (adds?:string) => {
    let tranAdd = searchAdd;
    try {
      tranAdd = encodeAddress(searchAdd,0)
      console.log('tranAdd')
      console.log(tranAdd)
    } catch (error) {
      
    }
    const res = await onHttp({
      "from": 1,
      "limit": 100,
      "address": adds?adds:tranAdd
    }, '/v2/Polkadot/Address');
    console.log(res)
    if (res && res.code == 0) {
      setsearchArr(res.data.list)
    }
  }

  const isCompleted = (minted: number, supply: number) => {
    if (minted) {
      if ((minted / supply) == 1) {
        return true
      }
      return false
    }
    return false
  }

  const onDetail = (item: any) => {
    navigate('/detail', { state: item })
  }

  // 分页
  const onPagination = (idx:number)=>{
    setpage(idx)
    getlist(((idx - 1)*limit) + 1);
  }

  const onClose = ()=>{
    setisDeploy(false)
  }

  return (
    <>
      {
        isDeploy && <Deploy onClose={onClose} />
      }
      
      <div className='app_box content'>
        {
          isSearch && <div className='onback' onClick={()=>{setisSearch(false);setsearchAdd('')}}>← back</div>
        }
      
        <div className='app_top'>
          <h2>check out Dotscriptions by address or token</h2>
          <div className='input_box'>
              <input type="text" value={searchAdd} onKeyDown={(ev) => onKeyDown(ev)} placeholder='Search by Address / Token' onChange={(ev) => {
                if(!ev.target.value){
                  setisSearch(false);
                  getlist(1,true)
                }
                setsearchAdd(ev.target.value)
              }} />
            <img onClick={()=>onSearch('')} src={searchImg} alt="search" />
          </div>
          <button className='phone' onClick={()=>setisDeploy(true)}>Deploy</button>
          {/* <p>check out token Dotscriptions balance of the address</p> */}
        </div>

        <div className='iconBOX'>
          <div>
            <img src={iconIMG} alt="" />
            <img src={iconIMG} alt="" />
          </div>
          
        </div>
        
        {/* 未搜索 */}
        {!isSearch && 
        <div className='app_con token_con'>
          <div className='flex_sb ph_deploy'>
            <h3>The full list of token Dotscriptions</h3>
            <button onClick={()=>setisDeploy(true)}>Deploy</button>
          </div>
          {/* <div className='input_box ph_input'>
            <input type="text" value={name} onKeyDown={(ev) => onKeyDown(ev,'name')} placeholder='Token' onChange={(ev) => {
              if(!ev.target.value){
                getlist(1,true)
              }
              setname(ev.target.value)
            }} />
            <img onClick={()=>getlist(1)} src={searchImg} alt="search" />
          </div> */}

          <div className='table_box'>
            <div className='table_head'>
              {
                tableHead.map((item, index) => <div key={index} >
                  <div>{item}</div>
                </div>)
              }
            </div>
            <div className='table_con'>
              <ul className='ul'>
                {isLoad && <Loading />}
                
                {!isLoad && listArr && listArr.length > 0 && listArr.map((item, idx) => <li key={idx} onClick={() => onDetail(item)}>
                  <div className='avatar'>
                    <i>{item.tick}</i>&nbsp;
                    <span>PRC-20</span>
                    {item.official && <><img src={rightImg} alt="" /></>}
                  </div>
                  <div className='tcolor'><span className='phone'>Deploy Time:</span> {onMomented(item.deployTime)}</div>
                  <div className='progress'>
                    {/* 100% */}
                    {item.minted == 0 ? <p>
                      <span className='phone'>Progress:</span>0.00%
                    </p> : <p>
                      <span className='phone'>Progress:</span>{Math.floor((item.minted / item.supply) * 10000) / 100}%
                    </p>}

                    <div>
                      <i style={{ width: ((item.minted / item.supply) * 100 + '%') }}></i>
                    </div>
                  </div>
                  <div><span className='phone'>Holders:</span>{item.holders}</div>
                  
                  <div className='flex_sb'>
                    <div><span className='phone'>Transaction:</span>{item.transactions}</div>
                    {
                      !isCompleted(item.minted, item.supply) && !item.stopMinted && <button className='btn_line'><img src={MintImg} alt="Mint" /> Mint</button>
                    }
                    {/* {
                      isCompleted(item.minted, item.supply) && <button className='btn_line' onClick={(ev) => {
                        ev.stopPropagation();
                      }}>Transfer</button>
                    } */}

                  </div>
                </li>)}
                {
                  !isLoad && listArr && listArr.length == 0 && <div className='nodata'>
                    Yay! You have seen it all
                  </div>
                }
              </ul>
              
              {
                (total !=null && total > limit ) && <Pagination
                  activePage={page||1}
                  prevPageText={''}
                  nextPageText={''}
                  itemsCountPerPage={limit}
                  totalItemsCount={total || 0}
                  pageRangeDisplayed={7}
                  onChange={(idx: number)=>{onPagination(idx)}}
                />
              }
            </div>
          </div>



        </div>
        }

        {
          isSearch && <SearchView searchArr={searchArr}  />
        }

        {/* 搜索 */}
      </div>
    </>
  );
}

export default Token;

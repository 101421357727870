import React, { useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import jsonrpc from '@polkadot/types/interfaces/jsonrpc';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import config from '../config';
import { networks } from '../util/api';
// import { compactStripLength } from '@polkadot/util';

const connectedSocket = networks.polkadot.rpcEndpoint;
const ss58Format = 0

const INIT_STATE = {
  ss58Format:ss58Format,
  socket: null,
  jsonrpc: { ...jsonrpc, ...config.RPC },
  types: config.CUSTOM_TYPES,
  keyring: null,
  keyringState: null,
  api: null,
  apiError: null,
  apiState: null,
  accounts:null,
  address:null,
  netName:null,
  block:true
};

// Reducer function for `useReducer`

const reducer = (state, action) => {
  switch (action.type) {
    
    case 'setNetName':
      return { ...state, socket: action.payload };

    case 'getBlock':
      return { ...state, block: action.payload };

    case 'setAddrerss':
      return { ...state, address: action.payload };

    case 'getAccounts':
      return { ...state, accounts: action.payload };

    case 'CONNECT_INIT':
      return { ...state, apiState: 'CONNECT_INIT' };

    case 'CONNECT':
      return { ...state, api: action.payload, apiState: 'CONNECTING' };

    case 'CONNECT_SUCCESS':
      return { ...state, apiState: 'READY' };

    case 'CONNECT_ERROR':
      return { ...state, apiState: 'ERROR', apiError: action.payload };

    case 'LOAD_KEYRING':
      return { ...state, keyringState: 'LOADING' };

    case 'SET_KEYRING':
      return { ...state, keyring: action.payload, keyringState: 'READY' };

    case 'KEYRING_ERROR':
      return { ...state, keyring: action.payload, keyringState: 'ERROR' };

    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};


// Connecting to the Substrate node

const connect = async(state, dispatch,netName) => {
  console.log('connect-----')
  const { apiState, jsonrpc } = state;
  // We only want this function to be performed once

  let localSocket = localStorage.getItem('setNetName') || connectedSocket;

  if(!netName){
    if (apiState) return;
  }

  dispatch({ type: 'CONNECT_INIT' });

  try {
    const provider = new WsProvider(netName?netName:localSocket)
    // debugger
    const _api = new ApiPromise({
      provider,
      types:config.types,
      rpc: jsonrpc
    });

    _api.isReadyOrError.then(()=>{
    }).catch(()=>{
      dispatch({ type: 'CONNECT_ERROR', payload: 'ERROR' })
    })

  // Set listeners for disconnection and reconnection event.
  _api.on('connected', async() => {
    // console.log("连接中……")
      dispatch({ type: 'CONNECT', payload: _api });
      // console.log(_api.isReady)
      // `ready` event is not emitted upon reconnection and is checked explicitly here.

      // getBloack(_api, dispatch)
      try {
        _api?.isReady?.then(async (_api) => {
          if(netName){
            localStorage.setItem('setNetName',netName)
          }
            dispatch({ type: 'CONNECT_SUCCESS' });
            dispatch({ type: 'setNetName' ,payload:netName?netName:localSocket})
        })
      } catch (error) {
        dispatch({ type: 'CONNECT_ERROR', payload: 'ERROR' })
        console.log(error)
      }
  });
  _api.on('ready', () => {
    dispatch({ type: 'CONNECT_SUCCESS' });
    // console.log("连接成功")
  });
  _api.on('error', (err) => dispatch({ type: 'CONNECT_ERROR', payload: err }));
  } catch (error) {
    // debugger
    console.log('err:'+error)
  }
  
};

// const getBloack = async(api,dispatch)=>{
//   const unsubscribe = await api.rpc.chain.subscribeNewHeads((header) => {
//     console.log(`Chain is at block: #${header.number}`);
//     if(header.number >= 18810896){
//       console.log('ok')
//       dispatch({ type: 'getBlock' ,payload:true})
//     }
//     unsubscribe()
//   });
// }

const loadAccounts = (state, dispatch) => {
  console.log('loadAccounts-----')
  const asyncLoadAccounts = async () => {
    console.log('async--')
    dispatch({ type: 'LOAD_KEYRING' });
    // console.log('LOAD_KEYRING')
    try {
      let res = await web3Enable(config.APP_NAME);

      console.log('res')
      console.log(res)
      // returns an array of { address, meta: { name, source } }
      // meta.source contains the name of the extension that provides this account
      let allAccount = await web3Accounts({ ss58Format: networks.polkadot.ss58Format });
      console.log('allAccount')
      console.log(allAccount)
      // debugger
      if (allAccount && allAccount.length > 0) {
          dispatch({ type: 'getAccounts', payload: allAccount });
          dispatch({ type: 'setAddrerss', payload: allAccount[0].address });
          localStorage.setItem('address',allAccount[0].address)
      }else{
          dispatch({ type: 'KEYRING_ERROR' });
      }

      /* let res = await web3Enable();
      console.log('res')
      console.log(res)
      let allAccounts = await web3Accounts({ss58Format:ss58Format});
      // allAccounts = allAccounts.map(({ address, meta }) => ({
      //   address,
      //   meta: { ...meta, name: `${meta.name}` }
      // }));
      // console.log(allAccounts);
      if(allAccounts && allAccounts.length > 0){
        console.log('SET_KEYRING')
        // keyring.loadAll({ isDevelopment: false }, allAccounts);
        dispatch({ type: 'getAccounts', payload: allAccounts });
        dispatch({ type: 'setAddrerss', payload: allAccounts[0].address });
        dispatch({ type: 'SET_KEYRING', payload: keyring });
        keyring.loadAll({ isDevelopment: false }, allAccounts);
      } */
      
    } catch (e) {
      console.log(e);
      console.log('xxxx')
      dispatch({ type: 'KEYRING_ERROR' });
    }
  };

  const { keyringState } = state;
  // // If `keyringState` is not null `asyncLoadAccounts` is running.
  if (keyringState) return;
  // // If `loadAccts` is true, the `asyncLoadAccounts` has been run once.
  // if (loadAccts) return dispatch({ type: 'SET_KEYRING', payload: keyring });
  // // This is the heavy duty work
  // loadAccts = true;

  asyncLoadAccounts();
};

const SubstrateContext = React.createContext();


const SubstrateContextProvider = (props) => {
  // filtering props and merge with default param value
  const initState = { ...INIT_STATE };
  const neededPropNames = ['socket', 'types'];
  neededPropNames.forEach((key) => {
    initState[key] = typeof props[key] === 'undefined' ? initState[key] : props[key];
  });

  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(()=>{
    connect(state, dispatch);
    loadAccounts(state, dispatch);
  },[])

  return <SubstrateContext.Provider value={{ state, dispatch }}>{props.children}</SubstrateContext.Provider>;
};

// prop typechecking
SubstrateContextProvider.propTypes = {
  socket: PropTypes.string,
  types: PropTypes.object
};

const useSubNode = () => useContext(SubstrateContext);

const useSubstrate = () => ({ ...useContext(SubstrateContext) });

export { SubstrateContextProvider, useSubNode, SubstrateContext, useSubstrate, connect, reducer, INIT_STATE , loadAccounts };

export function _extractEvents(api, result) {
  if (!result || !result.events) {
    return;
  }
  let success = false;
  let error;
  result.events
    .filter((event) => !!event.event)
    .map(({ event: { data, method, section } }) => {
      if (section === 'system' && method === 'ExtrinsicFailed') {
        const [dispatchError] = data;
        let message = dispatchError.type;

        if (dispatchError.isModule) {
          try {
            const mod = dispatchError.asModule;
            const error = api.registry.findMetaError(new Uint8Array([mod.index.toNumber(), mod.error.toNumber()]));

            message = `${error.section}.${error.name}`;
          } catch (error) {
            // swallow error
          }
        }
        // window.send('txUpdateEvent', {
        //   title: `${section}.${method}`,
        //   message
        // });
        error = message;
      } else {
        // window.send('txUpdateEvent', {
        //   title: `${section}.${method}`,
        //   message: 'ok'
        // });
        if (section == 'system' && method == 'ExtrinsicSuccess') {
          success = true;
        }
      }
    });
  return { success, error };
}

// export function formatterNumber(amount, decimals) {
//   var defaultBN = new BN(Math.pow(10, decimals));
//   var result;
//   // 通过 mnemonic 获取详细信息
//   console.log(amount);
//   const isDecimalValue = amount.match(/^(\d+)\.(\d+)$/);
//   if (isDecimalValue) {
//     const div = new BN(amount.replace(/\.\d*$/, '')); // 取整数
//     const modString = amount.replace(/^\d+\./, '').substr(0, decimals); //取小数

//     const mod = new BN(modString);
//     result = div.mul(defaultBN).add(mod.mul(BN_TEN.pow(new BN(decimals - modString.length))));
//   } else {
//     result = new BN(amount.replace(/[^\d]/g, '')).mul(defaultBN);
//   }

//   return result;
// }


import  { useContext, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router-dom';
import { onAdd, onHttp } from '../../util/api';
import reImg from '../../assets/img/refresh.svg'
import gzImg from '../../assets/img/gz.png'
import TipModal from '../../component/TipModal';
import { CheckoutHead } from '../../component/ModalHead';
import { ForCheckoutContent } from '../../component/ModalContent';
// import { SubstrateContext } from '../../substrate-lib';
import BigNumber from "bignumber.js"

function Listed(props: any) {

  const navigate = useNavigate();
  // const { state: { address , block } } = useContext(SubstrateContext);
  const did = props.did

  const [page, setpage] = useState<number>(1)
  const limit = 10
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null)
  const [isForSaleOpen, setIsForSaleOpen] = useState<boolean>(false);

  const [buyitem,setbuyitem] = useState<any>(null)


  const getlist = async (from: Number, falg?: boolean) => {
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "did": did
    }, '/v2/Polkadot/MarketListed');
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setlistArr(res.data.list)
    }
  }

  useEffect(() => {
    getlist(1);
  }, []);

  const onPagination = (idx: number) => {
    setpage(idx)
    getlist(((idx - 1) * limit) + 1);
  }

  return <div className='hodlers_box'>

      {
        buyitem && <TipModal
        type="forCheckout"
        isOpen={isForSaleOpen}
        onClose={() => setIsForSaleOpen(false)}
        head={<CheckoutHead />}
        children={<ForCheckoutContent data={buyitem} onClose={()=>setIsForSaleOpen(false)} />}
      />
      }
    
      
    <div className='flex_sb'>
      <div className='flex_sb hodlers_result'>
        <p>Result: <span>{total}</span></p>
        <img onClick={() => { getlist(1) }} src={reImg} alt="" />
      </div>
    </div>

    <div className='hodlers_list'>
      <ul className='hul'>
        {
          listArr.map((item, idx) => <li key={idx}  
          // onClick={() => {navigate('/listed', { state: item })}}
        >
            <div>
              <h3>{item.tick} {item.resonate && <img src={gzImg} />} </h3>
              <div className='hodlers_border'>
                <span>PRC-20</span>
                <span>mint</span>
                <span>#{item.height}</span>
              </div>
              <p className='hodlers_p'>{item.amt}</p>
              <p className='hodlers_p1'><i>${BigNumber(item.usdPrice).toFormat()}</i> / {item.tick}</p>
            </div>

            <div className='hodlers_list_bot'>
              <div className='flex_sb'>
                <p>#{item.no}</p>
                <p>{onAdd(item.seller)}</p>
              </div>
              <div className='flex_sb'>
                <p>{item.totalPrice} DOT</p>
                <span>${item.usdTotalPrice}</span>
              </div>
              <button onClick={(ev)=>{
                ev.stopPropagation();
                setbuyitem(item);
                setIsForSaleOpen(true)}
              }>BUY</button>
              
            </div>
          </li>)
        }
        {
          listArr.length == 0 && <div className='nodata'>
            Yay! You have seen it all
          </div>
        }
      </ul>

      {
        (total != null && total > limit) && <Pagination
          activePage={page || 1}
          prevPageText={''}
          nextPageText={''}
          itemsCountPerPage={limit}
          totalItemsCount={total || 0}
          pageRangeDisplayed={7}
          onChange={(idx: number) => { onPagination(idx) }}
        />
      }
    </div>
  </div>
}

export default Listed
import React, { useEffect, useState } from 'react';

import maxImg from '../assets/img/max.svg'

import { onHttp, onMomented } from '../util/api';
import Pagination from 'react-js-pagination';

function SearchView(props: any) {

  // const [itemType,setitemType] = useState<string>('')
  const [itemArr, setitemArr] = useState<Array<any>>([]);
  const [page, setpage] = useState<number>(1)
  const limit = 12
  const [total, settotal] = useState<number | null>(null)

  const [isselect, setisselect] = useState<boolean>(false)

  const searchArr = props.searchArr
  const [itemObj, setitemObj] = useState<any>(null)
  const [name, setName] = useState<any>(null)

  useEffect(() => {
    if (searchArr && searchArr.length > 0) {
      setitemObj(searchArr[0])
      setName(searchArr[0].tick)
    } else {
      setitemArr([])
    }
  }, [searchArr])

  useEffect(() => {
    if (name) {
      setpage(1)
      AddressItemList(1, itemObj.address, name)
    }
  }, [name])

  const AddressItemList = async (from: Number, myadd: string, ticks: string) => {
    const res = await onHttp({
      "from": from,
      "limit": limit,
      "address": myadd,
      "tick": ticks
    }, '/v2/Polkadot/AddressItemList');
    console.log(res)
    if (res && res.code == 0) {
      settotal(res.data.total)
      setitemArr(res.data.list)
    }
  }

  // 分页
  const onPagination = (idx: number) => {
    setpage(idx)
    AddressItemList(((idx - 1) * limit) + 1, itemObj.address, itemObj.tick);
  }

  return (
    <div className='app_search'>
      {/* 搜索 */}
      <h4>
        <span onClick={() => setisselect(!isselect)}>
          {name ? <>{name}<i>RPC-20</i></> : 'All Tokens'}
          <u className={isselect ? 'tranimg' : ''}></u>
        </span>
        {
          isselect && <div className='select_box'>
            <div className='select_head'>
              <div>
                <div>Token</div>
              </div>
              <div>
                <div>Amount</div>
              </div>
            </div>


            <div className='select_con'>
              <ul className='ul'>
                {searchArr.length > 0 && searchArr.map((item: any, idx: React.Key | null | undefined) => <li key={idx} onClick={
                  () => { setisselect(false); setName(item.tick) }
                }>
                  <div className='avatar'>
                    <i>{item.tick}</i>&nbsp;
                    <span>PRC-20</span>
                  </div>
                  <div className='web'>{item.amt}</div>
                  {/* <div className='web'>{onAdd(item.address)}</div> */}
                </li>)}
                {
                  searchArr.length == 0 && <div className='nodata'>
                    No results found.
                  </div>
                }
              </ul>
            </div>
          </div>
        }
      </h4>

      <div className='search_box'>
        <ul className='ul'>
          {
            itemArr.map((item, index) => <li key={index}>
              {item.amt == item.limit && <img src={maxImg} alt="" />}
              
              <h5>{item.tick}</h5>
              <div>
                <p>Number:</p>
                <span>#{item.no}</span>
              </div>
              <div>
                <p>Height:</p> 
                <span>#{item.blockNumber}</span>
              </div>
              <div>
                <p>Amount:</p>
                <span>{item.amt}</span>
              </div>
              <div>
                <p>Created:</p>
                <span>{onMomented(item.setupTime)}</span>
              </div>
            </li>)
          }

          {
            itemArr.length == 0 && <div className='nodata'>
              Yay! You have seen it all
            </div>
          }

        </ul>
        {
          (total != null && total > limit) && <Pagination
            activePage={page || 1}
            prevPageText={''}
            nextPageText={''}
            itemsCountPerPage={limit}
            totalItemsCount={total || 0}
            pageRangeDisplayed={7}
            onChange={(idx: number) => { onPagination(idx) }}
          />
        }
      </div>
    </div>
  );
}

export default SearchView;

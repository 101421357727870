
const Loading = () => {
  return <div className="loading_main">
    <div className='loading_box'>
    <svg className="loading_con" viewBox="22 22 44 44">
        <circle className="loading_circle" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6">
        </circle>
    </svg>
    </div>
    </div>
}

export default Loading;
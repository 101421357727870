
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import closeImg from '../assets/img/close.svg'
import { ForFailContent, ForSuccessfulContent } from '../component/ModalContent';
import TipModal from '../component/TipModal';
import { SubstrateContext } from '../substrate-lib';
import { _extractEvents } from '../substrate-lib/SubstrateContext';
import { decimal, DotData, onHttp, onWeb3FromSource2 } from '../util/api';
import loadImg from '../assets/img/loading.gif'

const Mint = (props: any) => {

    const { state: { address, api } } = useContext(SubstrateContext);

    const objData = props.data

    const [gas, setgas] = useState<string>('--')
    const [total, settotal] = useState<string>('--')
    const [mytx, setmytx] = useState<string>('--')

    const [isFailOpen, setIsFailOpen] = useState<boolean>(false);
    const [isSuccessfulOpen, setIsSuccessfulOpen] = useState<boolean>(false);
    const [text, settext] = useState<string>('')
    const [loading, setloading] = useState<boolean>(false)


    useEffect(()=>{
        if(objData){
            init()
        }
    },[objData])

    const init = async()=>{
        try {
            let tx: any;
            if (objData.fee == 0) {
                tx = api.tx.system.remarkWithEvent(DotData.Mint(objData.tick))
            } else {
                var txs = [
                    api.tx.system.remarkWithEvent(DotData.Mint(objData.tick)),
                    api.tx.balances.transfer(objData.feeTo, objData.fee * Math.pow(10, decimal)),
                ]
                tx = await api.tx.utility.batchAll(txs)
            }
            setmytx(tx)
            const { partialFee } = await tx.paymentInfo(address);
            let res:any = (partialFee / Math.pow(10,decimal)).toFixed(8);
            setgas(res)
            let totals = parseFloat(res)  + objData.fee
            totals =  Math.floor((totals) * Math.pow(10,8)) / Math.pow(10,8)
            settotal(totals)

        } catch (error) {
            // init()
            props.onClose()
        }
        
        // console.log()
    }

    const onDisabled = () => {
        if (!loading) {
            return false
        }
        return true
    }

    const onMint = async () => {

        if (!address) {
            toast('Please connect the address')
            return
        }
        setloading(true)
        settext('loading…')
        // let injected = await web3FromAddress(address).

        onWeb3FromSource2(mytx, address, (result: any) => {
            if (result.status.isBroadcast) {
                settext('broadcast…')
            }

            if (result.status.isFinalized) {
                const { success, error }: any = _extractEvents(api, result)

                if (success) {
                    settext('success')
                    setIsSuccessfulOpen(true)
                    toast.success('success')
                }
                if (error) {
                    settext('failed')
                    setIsFailOpen(true)
                }
                setloading(false)
            }
            if (result.status.isInBlock) {
                settext("broadcast…")
            }
            if (result.status.isCancelled) {
                settext('cancellation')
                setloading(false)
            }
        }, (err: any) => {
            settext(err.message)
            setIsFailOpen(true)
            setloading(false)
        })

    }

    function check(str: string) {
        var temp = ""
        for (var i = 0; i < str.length; i++)
            if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 255)
                temp += str.charAt(i)
        return temp
    }

    return <div className='deploy_box mint_box'>
        {
            isFailOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => { setIsFailOpen(false) }}
                children={<ForFailContent title={text} title1="" />}
            />
        }
        {
            isSuccessfulOpen && <TipModal
                type="fail"
                isOpen={true}
                onClose={() => { setIsSuccessfulOpen(false); props.onClose() }}
                children={<ForSuccessfulContent title={text} title1="" />}
            />
        }
        {!isSuccessfulOpen &&
            <div>
                <div className='deploy_top flex_sb'>
                    <h3>PRC-20</h3>
                    <img onClick={() => { props.onClose() }} src={closeImg} alt="" />
                </div>
                <div className='deploy_con'>
                    <div>
                        <h4>{objData.tick}</h4>
                    </div>
                    <div>
                        <span>Commission:</span>
                        <p>{objData.fee} DOT</p>
                    </div>
                    <div>
                        <span>Gas fee:</span>
                        <p>{gas} DOT</p>
                    </div>
                    <div>
                        <span>You pay:</span>
                        <p>{total} DOT</p>
                    </div>
                </div>
                <button className={onDisabled() ? 'disbaleb_btn' : ''} disabled={onDisabled()} onClick={onMint}>
                    {loading ? <><img src={loadImg} alt="loading" style={{ width: '16px' }} />{text}</> : 'Mint'}
                </button>
                <div className='btn_box'>
                    {/* <button className='lines' onClick={()=>props.onClose()}>Cancal</button> */}
                </div>
            </div>
        }

    </div>
}

export default Mint

import { Fragment, useContext, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import TipModal from "../../component/TipModal";
import {
  ForSaleHead,
  TransferHead,
} from "../../component/ModalHead";
import {ForSaleContent,ForTranferContent, } from '../../component/ModalContent'
import { useNavigate } from "react-router-dom";
import { goHash, onAdd, onHttp } from "../../util/api";
import reImg from "../../assets/img/refresh.svg";
import jtimg from "../../assets/img/jt.svg";
import maxImg from '../../assets/img/max.svg'
import { SubstrateContext } from "../../substrate-lib";
import gzImg from '../../assets/img/gz.png'
import toast from "react-hot-toast";

function Collected(props:any) {

  const isCreate = props.create

  const { state: { address , block } } = useContext(SubstrateContext);

  const [page, setpage] = useState<number>(1);
  const limit = 10;
  const [listArr, setlistArr] = useState<Array<any>>([]);
  const [total, settotal] = useState<number | null>(null);

  const [buyitem,setbuyitem] = useState<any>(null)
  const [isForSaleOpen, setIsForSaleOpen] = useState<boolean>(false);
  const [isForTransferOpen, setIsForTransferOpen] = useState<boolean>(false);

  useEffect(()=>{
    if(address){
      getlist(1)
    }
  },[address])

  const refrese = ()=>{
    if(address){
      getlist(1)
    }
  }

  const handleModalClose = (type: string) => {
    switch (type) {
      case "forSale":
        setIsForSaleOpen(false);
        break;
      case "forTransfer":
        setIsForTransferOpen(false);
        break;
      default:
        break;
    }
  };

  const getlist = async (from: Number) => {
    const res = await onHttp(
      {
        from: from,
        limit: limit,
        address: address,
      },
      "/v2/Polkadot/"+(isCreate?"OwnCreate":"OwnList")
    );
    console.log(res);
    if (res && res.code == 0) {
      settotal(res.data.total);
      setlistArr(res.data.list);
    }
  };

  // 分页
  const onPagination = (idx: number) => {
    setpage(idx);
    getlist((idx - 1) * limit + 1);
  };

  return (
    <Fragment>
      <div className="hodlers_box">
        <div className="flex_sb">
          <div className="flex_sb hodlers_result">
            <p>
              Result: <span>{total}</span>
            </p>
            <img onClick={refrese} src={reImg} alt="" />
          </div>
          {/* <div className="hodlers_option">
            <div className="more">
              <p>Show All</p>
              <img src={jtimg} alt="" />
            </div>
          </div> */}
        </div>

        <div className="hodlers_list">
          <ul className='hul'>
            {
              listArr.map((item,idx)=><li key={idx}>
                { item.amt == item.limit && <img src={maxImg} /> }
              <div>
                <h3>{item.tick} {item.resonate && <div><img src={gzImg} /></div>}</h3>
                <div className="hodlers_border">
                  <span>prc-20</span>
                  <span>mint</span>
                  <span>#{item.blockNumber}</span>
                </div>
                <p className="hodlers_p">{item.amt}</p>
                <p className="hodlers_p1">
                  <i>${item.price?(item.usdPrice):'--'}</i> / {item.tick}
                </p>
              </div>

              <div className="hodlers_list_bot">
                <div className="flex_sb">
                  <p>#{item.no}</p>
                  <p onClick={()=>goHash(item.hash)}>{onAdd(item.hash)}</p>
                  {/* <p>{onAdd(item.owner)}</p> */}
                </div>
                <div className="flex_sb">
                  <p>{item.totalPrice?item.totalPrice:'--'} DOT</p>
                  <span>${item.usdTotalPrice?item.usdTotalPrice:'--'}</span>
                </div>
                
                  <div className="flex_btn" style={{'opacity':item.saled?0.5:1}}>
                   <button
                    onClick={() => {
                      if(item.saled){return}
                      setbuyitem(item);setIsForSaleOpen(true)
                    }}>
                      Sale
                    </button>
                <button onClick={() => {
                  if(item.saled){return}
                  setbuyitem(item);setIsForTransferOpen(true)
                  }}>Transfer</button>
                </div>
              </div>
            </li>)
            }
            {
              listArr.length == 0 && <div className='nodata'>
                Yay! You have seen it all
              </div>
            }
            
          </ul>
          {
            (total !=null && total > limit ) && <Pagination
              activePage={page||1}
              prevPageText={''}
              nextPageText={''}
              itemsCountPerPage={limit}
              totalItemsCount={total || 0}
              pageRangeDisplayed={7}
              onChange={(idx: number)=>{onPagination(idx)}}
            />
          }
        </div>

      </div>
      {
        isForSaleOpen && buyitem && <TipModal
        type="forSale"
        isOpen={isForSaleOpen}
        onClose={() => handleModalClose("forSale")}
        head={<ForSaleHead name={buyitem.tick} />}
        children={<ForSaleContent data={buyitem} onClose={()=>handleModalClose("forSale")} />}
      />
      }
      
      {
        isForTransferOpen && buyitem && <TipModal
          type="forTranfer"
          isOpen={isForTransferOpen}
          onClose={() => handleModalClose("forTransfer")}
          head={<TransferHead name={buyitem.tick} />}
          children={<ForTranferContent data={buyitem} onClose={()=>handleModalClose("forTransfer")} />}
        />
      }
      

    </Fragment>
  );
}

export default Collected;

import React from 'react';
import { createHashRouter } from "react-router-dom";
import App from './App';
import Profile from './pages/Profile';
import Marketplace from './pages/Marketplace';
import Details from './pages/Details';
import MarketDetail from './pages/MarketDetail';
import ListedDetail from './pages/ListedDetail';

import Presale from './pages/Presale';
import Token from './pages/Token';

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        children:[
            {
                path: '/',
                element: <Presale />,
            },
            {
                path: '/token',
                element: <Token />,
            },
            {
                path: '/marketplace',
                element: <Marketplace />,
            },
            {
                path: '/marketdetail',
                element: <MarketDetail />,
            },
            {
                path: '/listed',
                element: <ListedDetail />,
            },
            {
                path: '/profile',
                element: <Profile />,
            },
            {
                path: '/detail',
                element: <Details />,
            }
        ]
    },
    
]);
console.log(router)

export default router;

import { useContext, useEffect, useState } from 'react';
// import Hedaer from '../component/Hedaer';
import Collected from './profile/Collected';
import avatarImg from '../assets/img/avatar.png'
import { SubstrateContext } from '../substrate-lib';
import { copyPlaintext, onAdd } from '../util/api';
import InviterView from './InviterView';
import toast from 'react-hot-toast';

function Profile() {

    const { state:{ address } } = useContext(SubstrateContext);
    const [type,settype] = useState<string>('Collected')

    const tableHead = [
      'Collected',
      'Created',
      'Invited'
    ]

    const onCopy = async(url:string)=>{
      let res = await copyPlaintext(url);
      if(res){
        toast.success('copy success!')
      }else{
        toast.error('copy failed!')
      }
    }

  return (
    <>
        <div className='markde_detail_con content'>
          {address && <h3 className='profile_add'>
            <img src={avatarImg} alt="" />
            <p onClick={()=>onCopy(address)}>{onAdd(address)}</p>
          </h3>}
          
          <div className='markde_detail_tab'>
            {
              tableHead.map((item, index) => <span className={item == type?'ups':''} onClick={()=>{settype(item)}} key={index} >
                {item}
              </span>)
            }
          </div>
          
          { type == 'Collected' && <Collected />}
          { type == 'Created' && <Collected create={true} />}
          { type == 'Invited' && <div className='app_con'>
            <InviterView />
          </div>
          }
        </div>

    </>
  );
}

export default Profile;

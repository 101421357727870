import ReactDOM from 'react-dom/client';
import './assets/font/font.css'
import './assets/css/index.css';

import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './router';

// console.log = (function (oriLogFunc) {
//   return function () {}
// })(console.log)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// ReactDOM.render(
//   <App />,
// document.getElementById('root')
// );

root.render(
  <RouterProvider router={router} />
  // <React.StrictMode>
  //     <RouterProvider router={router} />
  // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
